import Decimal from 'decimal.js';
import { NotificationMode } from '../enums/notifications';
import { MerchantType } from '../interfaces/delivery-company/merchants';

export const emptyMerchant: MerchantType = {
  id: '',
  name: '',
  phone_number: '',
  address: '',
  has_usable_password: false,
  notification_mode: NotificationMode.NONE,
  default_delivery_fee_lbp: Decimal(0),
  default_delivery_fee_usd: Decimal(0),
};

import { Alert, Box, FormControl, Tab } from '@mui/material';
import React, { useContext, useState } from 'react';
import MerchantInvoicesListing from './merchant-invoices-listing.tsx/merchant-invoices-listing';
import MerchantOrdersListing from './invoiced-orders-listing/merchant-orders-listing';
import { DeliveryState } from '../../../../enums/orders';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ArticleIcon from '@mui/icons-material/Article';
import { tabLabel } from '../../../common/tab-label/tab-label';
import {
  CompactMerchantType,
  MerchantType,
} from '../../../../interfaces/delivery-company/merchants';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import axios, { AxiosResponse } from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import AutocompleteWithThrottle from '../../../common/autocomplete-with-throttle/autocomplete-with-throttle';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { UserType } from '../../../../enums/users';
import { ErrorsFlattenner } from '../../../common/errors-flattenner/errors-flattenner';

function MerchantAccounting() {
  const [merchant, setMerchant] = useState<MerchantType | null>(null);
  const [selectedTab, setSelectedTab] = React.useState<string>('invoices');
  const [message, setMessage] = useState<string>('');
  const [response, setReponse] = useState<AxiosResponse>();

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleFetchMerchants = async (
    query: string
  ): Promise<MerchantType[]> => {
    const endpoint = `${API_ENDPOINT}/api/v1/merchants/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

    try {
      const response = await axios.get<{
        count: number;
        results: MerchantType[];
      }>(endpoint, config);
      if (response.statusText === 'OK') {
        return response.data.results;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setReponse(undefined);
    setMessage('');
    setSelectedTab(newValue);
  };

  const switchToInvoices = () => {
    setSelectedTab('invoices');
  };

  return (
    <Box>
      <FormControl fullWidth>
        <AutocompleteWithThrottle
          label={'Merchant'}
          required={false}
          fetch={handleFetchMerchants}
          value={merchant}
          getOptionLabel={(option: CompactMerchantType) =>
            option.name + ' - ' + option.phone_number
          }
          onChange={(e, value) => {
            setMerchant(value);
          }}
        />
      </FormControl>
      <br />
      <br />

      {merchant !== null && (
        <>
          <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              {message !== '' && (
                <>
                  <Alert
                    severity={
                      response &&
                      response.status >= 200 &&
                      response.status < 300
                        ? 'success'
                        : 'error'
                    }
                  >
                    <>
                      {message}

                      {response && response.status >= 300 && (
                        <ErrorsFlattenner rawErrors={response.data} />
                      )}
                    </>
                  </Alert>

                  <br />
                </>
              )}

              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="fullWidth"
                  >
                    <Tab
                      label={tabLabel(
                        <EventBusyIcon color="primary" />,
                        'Cancelled Orders'
                      )}
                      value="cancelled_orders"
                    />
                    <Tab
                      label={tabLabel(
                        <AllInboxIcon color="primary" />,
                        'Delivered Orders'
                      )}
                      value="delivered_orders"
                    />
                    <Tab
                      label={tabLabel(
                        <ArticleIcon color="primary" />,
                        'Invoices'
                      )}
                      value="invoices"
                    />
                  </TabList>
                </Box>
                <TabPanel value="cancelled_orders">
                  <MerchantOrdersListing
                    merchantId={merchant.id}
                    deliveryState={DeliveryState.CANCELLED}
                    switchToInvoices={switchToInvoices}
                    setResponse={setReponse}
                    setMessage={setMessage}
                  />
                </TabPanel>
                <TabPanel value="delivered_orders">
                  <MerchantOrdersListing
                    merchantId={merchant.id}
                    deliveryState={DeliveryState.DELIVERED}
                    switchToInvoices={switchToInvoices}
                    setResponse={setReponse}
                    setMessage={setMessage}
                  />
                </TabPanel>
                <TabPanel value="invoices">
                  <MerchantInvoicesListing
                    merchantId={merchant.id}
                    setResponse={setReponse}
                    setMessage={setMessage}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </>
        </>
      )}
    </Box>
  );
}

export default MerchantAccounting;

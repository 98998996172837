import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  LinearProgress,
} from '@mui/material';

import { MerchantType } from '../../../../interfaces/delivery-company/merchants';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { NotificationMode } from '../../../../enums/notifications';
import { display_notification_mode } from '../../../../utils/notification_mode_icons';

interface MerchantsListingTableProps {
  merchants: MerchantType[];
  isLoading: boolean;
}

function MerchantsListingTable({
  merchants,
  isLoading,
}: MerchantsListingTableProps) {
  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Table stickyHeader style={{ textAlign: 'center' }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <b>Name</b>
                </TableCell>
                <TableCell align="center">
                  <b>Phone</b>
                </TableCell>
                <TableCell align="center">
                  <b>Address</b>
                </TableCell>
                <TableCell align="center">
                  <b>Notifications Mode</b>
                </TableCell>
                <TableCell align="center">
                  <b>Has Account</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchants.map((merchant) => (
                <TableRow key={merchant.id}>
                  <TableCell align="center" style={{ cursor: 'pointer' }}>
                    <a href={`/merchant/${merchant.id}`}>
                      <b>{merchant.name}</b>
                    </a>
                  </TableCell>
                  <TableCell align="center">{merchant.phone_number}</TableCell>
                  <TableCell align="center">{merchant.address}</TableCell>
                  <TableCell align="center" >
                    {display_notification_mode(merchant.notification_mode)}
                  </TableCell>
                  <TableCell align="center">
                    {merchant.has_usable_password ? (
                      <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                    ) : (
                      <CancelIcon sx={{ color: 'red' }} />
                    )}{' '}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )
      }
    </TableContainer >
  );
}

export default MerchantsListingTable;

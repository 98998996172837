import { FilterMethods } from './filter-bar.interface';

export class DefaultFilter implements FilterMethods {
  key: string;
  value: string;
  labelId: string | undefined;

  constructor(key: string, value: string, labelId?: string) {
    this.key = key;
    this.value = value;
    this.labelId = labelId;
  }

  public get_key(): string {
    return this.key;
  }

  public get_value(): string {
    return this.value;
  }

  public to_string(): string {
    return `${this.key}: ${this.value}`;
  }

  public to_query(): string {
    if (this.labelId) return `label__${this.key}=${this.labelId}`;
    return `${this.key}=${encodeURIComponent(this.value)}`;
  }

  public to_query_pair(): { key: string; value: string } {
    if (this.labelId)
      return {
        key: `label__${this.key}`,
        value: this.labelId,
      };

    return {
      key: this.key,
      value: this.value,
    };
  }
}


import Papa, { ParseResult } from 'papaparse';
import { parseFloatWithPrecision } from '../../../utils/decimal';

export interface ParsedOrderType {
  order_id: string;
  reference_number: string;
  customer_name: string;
  customer_phone: string;
  address: string;
  district: string;
  price_lbp: number;
  price_usd: number;
  delivery_fee_usd: number;
  delivery_fee_lbp: number;
  number_of_packages: number;
  description: string;
}

export const parseFile = (
  file: File,
  setParseOrders: any,
  setParseResult: any
) => {
  if (file.type !== 'text/csv') {
    setParseResult({
      isSuccess: false,
      message: 'Failed to parse file',
      errors: `Unsupported file type ${file.type}. Only CSV files are supported.`,
    });
    return;
  }

  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: function (results: ParseResult<ParsedOrderType>) {
      console.log('completed parsing');
      const parseErrors = results.errors;

      if (parseErrors.length > 0) {
        setParseResult({
          isSuccess: false,
          message: 'Failed to read file due to the following errors',
          errors: parseErrors.map((item) => `${item.type}: ${item.message}`),
        });
        return;
      } else {
        console.log(results);
        if (
          results.meta.fields &&
          results.meta.fields.join(',') !==
          'order_id,reference_number,customer_name,customer_phone,address,district,price_usd,price_lbp,delivery_fee_usd,delivery_fee_lbp,number_of_packages,description'
        ) {
          setParseResult({
            isSuccess: false,
            message: 'Failed to read file due to the following errors',
            errors: ['File content not conforming with the required template'],
          });
          return;
        }
        const data = results.data.map((row) => {
          return {
            order_id: row.order_id.trim(),
            reference_number: row.reference_number,
            customer_name: row.customer_name,
            customer_phone: row.customer_phone,
            address: row.address,
            district: row.district,
            price_usd: parseFloatWithPrecision(row.price_usd.toString().replaceAll(",", "")),
            price_lbp: parseFloatWithPrecision(row.price_lbp.toString().replaceAll("", "")),
            delivery_fee_usd: parseFloatWithPrecision(row.delivery_fee_usd.toString().replaceAll(",", "")),
            delivery_fee_lbp: parseFloatWithPrecision(row.delivery_fee_lbp.toString().replaceAll(",", "")),
            number_of_packages: Number(row.number_of_packages as number),
            description: row.description,
          };
        });

        console.log("setting parsed orders to data")
        console.log("data = ", data)
        setParseOrders(data);
        setParseResult({
          isSuccess: true,
          message: 'Successfully parsed orders.',
          errors: [],
        });
      }
    },
  });
};

const getParsedOrderErrors = (parsedOrder: ParsedOrderType): string[] => {
  const errorMessages: string[] = [];

  if (isNaN(parsedOrder.price_usd)) {
    errorMessages.push('Failed to parse price_usd');
  }

  if (isNaN(parsedOrder.price_lbp)) {
    errorMessages.push('Failed to parse price_lbp');
  }

  if (isNaN(parsedOrder.delivery_fee_usd)) {
    errorMessages.push('Failed to parse delivery_fee_usd');
  }

  if (isNaN(parsedOrder.delivery_fee_lbp)) {
    errorMessages.push('Failed to parse delivery_fee_lbp');
  }

  if (isNaN(parsedOrder.number_of_packages)) {
    errorMessages.push('Failed to parse number_of_packages');
  }

  return errorMessages;
};

export const getParsedOrdersErrors = (
  parsedOrders: ParsedOrderType[]
): string[] => {
  const parseErrors: string[] = [];
  parsedOrders.forEach((parsedOrder, index) => {
    const rowParseErrors = getParsedOrderErrors(parsedOrder);
    if (rowParseErrors.length > 0)
      rowParseErrors.forEach((parseError) =>
        parseErrors.push('Issue with row #' + (index + 1) + ': ' + parseError)
      );
  });
  return parseErrors;
};

import ClearIcon from '@mui/icons-material/Clear';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useContext, useEffect } from 'react';
import Barcode from 'react-barcode';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { DeliveryState } from '../../../enums/orders';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import OrderInfo from './driver-order-lookup.order-info';
import { ErrorsFlattenner } from '../../common/errors-flattenner/errors-flattenner';

interface OrderLookupDialogProps {
  orderId: string;
  onCloseDialog: () => void;
  orgId?: string;
  searchByOrderId: boolean;
}

function OrderLookupDialog({
  orderId,
  onCloseDialog,
  orgId,
  searchByOrderId,
}: OrderLookupDialogProps) {
  const [order, setOrder] = React.useState<OrderType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [response, setResponse] = React.useState<AxiosResponse>();
  const [message, setMessage] = React.useState<string>('');

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  console.log('now response = ', response);
  console.log('now message = ', message);

  useEffect(() => {
    const fetchAndSetOrder = async () => {
      if (orgId) {
        setIsLoading(true);
        try {
          let endpoint = `${API_ENDPOINT}/api/v1/orders/?`;
          if (searchByOrderId)
            endpoint += `order_id__equals=${encodeURIComponent(orderId)}`;
          else endpoint += `ref_number__equals=${encodeURIComponent(orderId)}`;
          console.log(endpoint);
          const response = await axios.get<{
            count: number;
            results: OrderType[];
          }>(endpoint, config);
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK' && response.data.count === 1) {
            setOrder(response.data.results[0]);
            setMessage('Order Found!');
          } else {
            setResponse({ ...response, statusText: 'NOT FOUND' });
            setMessage('Order Not Found');
          }
        } catch (e) {
          setOrder(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load order data: ' + e.message);
            setResponse(e.response);
          } else {
            setMessage('Failed to load order data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    fetchAndSetOrder();
  }, [orderId]);

  const resetState = () => {
    setIsLoading(false);
    setOrder(undefined);
    setResponse(undefined);
  };

  const toggleOrderState = async () => {
    setResponse(undefined);
    setMessage('');

    if (order) {
      const newDeliveryState =
        order.delivery_state === DeliveryState.IN_TRANSIT
          ? DeliveryState.DELIVERED
          : DeliveryState.IN_TRANSIT;
      setIsLoading(true);

      const endpoint = `${API_ENDPOINT}/api/v1/order/${order.id}/?org=${orgId}`;
      let response;
      const newOrder = { ...order, delivery_state: newDeliveryState };
      try {
        response = await axios.put(endpoint, newOrder, config);
        if (response.status >= 200 && response.status < 300) {
          setMessage('Delivery state update successfully');
          setOrder(newOrder);
        } else {
          setMessage('Failed to update delivery state');
        }
        setResponse(response);
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setMessage('Failed to update delivery state: ' + e.message);
          setResponse(e.response);
        } else {
          setMessage('Failed to update delivery state');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  const addNote = async () => {
    setResponse(undefined);
    setMessage('');
    const note = prompt('Enter your note');

    if (order) {
      setIsLoading(true);

      const endpoint = `${API_ENDPOINT}/api/v1/order/${order.id}/?org=${orgId}`;
      let response;
      const newOrder = { ...order, driver_note: note || '' };
      try {
        response = await axios.put(endpoint, newOrder, config);
        if (response.status >= 200 && response.status < 300) {
          setMessage('Note saved successfully');
          setOrder(newOrder);
        } else {
          setMessage('Failed to save note');
        }
        setResponse(response);
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setMessage('Failed to save note: ' + e.message);
        } else {
          setMessage('Failed to save note');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  console.log('response: ', response);
  return (
    <div>
      <Dialog fullWidth open={true} onClose={() => undefined}>
        <DialogTitle>
          <div
            style={{
              textAlign: 'right',
              width: '100%',
            }}
          >
            <IconButton
              onClick={() => {
                resetState();
                onCloseDialog();
              }}
            >
              <ClearIcon color="primary" fontSize="large" />
            </IconButton>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {orderId !== '' && <Barcode width={3} value={orderId} />}
          </div>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              {message !== '' && (
                <Alert
                  severity={response?.statusText === 'OK' ? 'success' : 'error'}
                >
                  {message}
                  {response && response.status >= 300 && (
                    <ErrorsFlattenner rawErrors={response.data} />
                  )}
                </Alert>
              )}
              {order && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <OrderInfo order={order} />

                  <div>
                    {(order.delivery_state === DeliveryState.IN_TRANSIT ||
                      order.delivery_state === DeliveryState.DELIVERED) && (
                      <>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={toggleOrderState}
                        >
                          <>
                            {order.delivery_state ===
                              DeliveryState.IN_TRANSIT && 'Mark Delivered'}
                            {order.delivery_state === DeliveryState.DELIVERED &&
                              'Mark In Transit'}
                          </>
                        </Button>
                        <hr />
                        <Button fullWidth variant="outlined" onClick={addNote}>
                          {order.driver_note === '' ? 'Add' : 'Change'} Note
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrderLookupDialog;

import React, { useContext } from 'react';
import { CustomerType } from '../../../interfaces/common/customer';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { UserType } from '../../../enums/users';
import GenericPopover from '../generic-popover/generic-popover';
import CustomerPopoverDisplay from '../../merchant-components/utils/customer-popover-display';
import { Alert } from '@mui/material';

interface CustomerLinkOrPopoverProps {
    customer: CustomerType | null | undefined
}

function CustomerLinkOrPopover({ customer }: CustomerLinkOrPopoverProps) {
    const { user } = useContext(AuthContext)

    if (!user) return null
    if (!customer) return <Alert variant='outlined' severity='error'>Not Found</Alert>

    return (

        user.type === UserType.MERCHANT ? <GenericPopover display={<b>{customer.name !== "" ? customer.name : "No Name"}</b>}>
            <CustomerPopoverDisplay customer={customer} />
        </GenericPopover> :
            <a href={`/customer/${customer.id}`}>
                <b>{customer.name !== "" ? customer.name : "No Name"}</b>
            </a>
    );
}

export default CustomerLinkOrPopover;
import { Document, Font, Image, Page, StyleSheet } from '@react-pdf/renderer';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { PageFooter } from '../pdf-builders-utils/page-footer';
import { ReportTable } from './table';
import { pageStyles } from '../pdf-builders-utils/styles';
import { PageTitle } from '../pdf-builders-utils/page-title';
import { useEffect, useState } from 'react';

export interface IFieldConfig {
  display_name: string[];
  width_percentage: string;
  get_values: (order: OrderType) => string[];
  get_aggregate?: (orders: OrderType[]) => string[];
}

export interface IFooterRow {
  label: string;
  compute_value: (orders: OrderType[]) => string;
}

interface Table {
  fields_config: IFieldConfig[];
  title: string;
  header_bg: string;
  orders: OrderType[];
  table_footer_rows: IFooterRow[];
}

export interface ReportProps {
  tables: Table[];
  organisation_id: string;
  date_from: string;
  date_to: string;
  title: string;
  subtitles?: string[];
  color: string;
}

export const ReportDocument = (reportProps: ReportProps) => {
  return (
    <Document>
      <Page size="A4" style={pageStyles.page} wrap={true}>
        <Image
          style={pageStyles.logo}
          src={`https://parcel-tracer-public.s3.eu-central-1.amazonaws.com/${reportProps.organisation_id}/logo.png`}
        />
        <PageTitle {...reportProps} />
        {reportProps.tables.map((table, index) => (
          <ReportTable
            key={index}
            table_footer_rows={table.table_footer_rows}
            on_new_page={index > 0}
            orders={table.orders}
            fields_config={table.fields_config}
            title={table.title}
            header_bg={table.header_bg}
            color={reportProps.color}
          />
        ))}
        <PageFooter color={reportProps.color} />
      </Page>
    </Document>
  );
};

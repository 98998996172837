import React, { useContext, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { BulkActionProps } from './props.interface';
import { FormControl, Button, TextField } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import WaybillPrinter from '../waybill-printer/waybill-printer';
import { AuthContext } from '../../../contexts/user-context/user-context';

function PrintWaybills({ selectedOrders }: BulkActionProps) {
  const fileName = 'waybills';
  const { user } = useContext(AuthContext);
  const orgId = user?.organisation?.org_id ?? 'UNKNOWN';

  const [waybillsPerPage, setWaybillsPerPage] = useState(1);
  const [startGenerating, setStartGenerating] = useState(false);

  return (
    <>
      {!startGenerating && (
        <div
          style={{
            display: 'flex',
            gap: 5,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            value={waybillsPerPage}
            type="number"
            variant="outlined"
            fullWidth
            required
            onChange={(e) => {
              setWaybillsPerPage(parseInt(e.target.value));
            }}
            onFocus={(e) =>
              e.target.addEventListener('wheel', (e) => e.preventDefault(), {
                passive: false,
              })
            }
          />

          <Button
            color="primary"
            variant="outlined"
            onClick={() => setStartGenerating(true)}
          >
            <b>Print Waybills</b>
          </Button>
        </div>
      )}

      {startGenerating && (
        <FormControl fullWidth>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {
              <PDFDownloadLink
                document={
                  <WaybillPrinter
                    orgId={orgId}
                    orders={Array.from(selectedOrders.values())}
                    waybillsPerPage={waybillsPerPage}
                  />
                }
                fileName={fileName}
              >
                {({ blob, url, loading, error }) => {
                  return loading ? (
                    <Button color="primary" size="large" disabled>
                      Loading...
                    </Button>
                  ) : (
                    <Button color="primary" size="large">
                      <DownloadIcon fontSize="large" />
                    </Button>
                  );
                }}
              </PDFDownloadLink>
            }
          </div>
        </FormControl>
      )}
    </>
  );
}

export default PrintWaybills;

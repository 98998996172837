import React, { useContext, useEffect, useState } from 'react';
import { Card, TablePagination } from '@mui/material';

import SearchBar from '../../../common/search-bar/search-bar';
import CustomersListingTable from './customers-listing.table';
import { CustomerType } from '../../../../interfaces/common/customer';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import axios from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { PaginationContext } from '../../../../contexts/pagination-context';

function CustomersListing() {
  const [customersList, setCustomersList] = useState<CustomerType[]>([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const searchOptions = ['Name', 'Phone', 'Address'];
  const { user } = useContext(AuthContext);

  const { paginationConfig, setPaginationConfig } = useContext(PaginationContext)

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPage,
    searchColumn: 'Name',
    searchValue: '',
    searchOperation: 'equals',
  });

  useEffect(() => {
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/org/customers/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}`;

      if (controller.searchColumn !== '' && controller.searchValue !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(controller.searchValue)
          } `;
      }

      console.log(endpoint);
      try {
        const config = {
          headers: { Authorization: `Bearer ${user?.authToken} ` },
        };

        const response = await axios.get<{
          count: number;
          results: CustomerType[];
        }>(endpoint, config);
        console.log(response);

        if (response.statusText === 'OK') {
          setCustomersList(response.data.results);
          setCustomersCount(response.data.count);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [controller]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setPaginationConfig({ ...paginationConfig, rowsPerPage: newRowsPerPage })
    setController({
      ...controller,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      page: 0,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  return (
    <Card sx={{ padding: 2, overflow: 'auto' }}>
      <SearchBar
        searchOptions={searchOptions}
        searchQueryAction={searchQueryAction}
      />

      <CustomersListingTable customers={customersList} isLoading={isLoading} />

      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={customersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
        labelRowsPerPage=""
        style={{ overflow: 'visible' }}
      />
    </Card>
  );
}

export default CustomersListing;

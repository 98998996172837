import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { VersionedLogo } from '../settings';
import axios from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { OrganisationSettings } from '../../../../interfaces/delivery-company/orders';
import { OrgThemeContext } from '../../../../contexts/theme-context';

// Define the site's color palette
const secondaryPaletteColor = '#eee'; // Light gray for subtle elements
const sentMessageColor = '#ece5dd'; // WhatsApp green for sent messages
const screenBackgroundColor = '#f4f4f4'; // Soft gray for the screen background

interface NotificationManagerProps {
  logoUrl: string;
  settings: OrganisationSettings;
}
function NotificationManager({ logoUrl, settings }: NotificationManagerProps) {
  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };
  const { color } = useContext(OrgThemeContext);

  const [content, setContent] = useState<string>(
    settings.notification_content ?? ''
  );
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Success message state

  // Handle text input changes
  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleSubmit = async () => {
    setError(null);
    setSuccessMessage(null);
    setUploading(true);

    try {
      const requestData = {
        notification_content: content,
      };
      const response = await axios.post(
        `${API_ENDPOINT}/api/org/settings`,
        requestData,
        config
      );
      setSuccessMessage('Message saved successfully');
    } catch (err) {
      setError('Failed to save message. Please try again.');
    }

    setUploading(false);
  };
  // Generate the message content with user input injected into the template
  const generateMessageContent = (userInput: string) => {
    const template = `
Hello Jack 👋🏻

You order from Toy Shop is ready for delivery by ${user?.organisation?.org_id}.

You can track your order by clicking on the link parceltracer.app/tracking/123.
${userInput.length > 0 ? `\n` + userInput + `\n` : userInput}
Have a nice day!
${user?.organisation?.org_id} team
        `;

    // Trim the template to remove excess whitespace or newlines at the beginning/end
    return template.trim();
  };

  // Convert the content with line breaks to HTML-friendly format
  const formatMessageWithLineBreaks = (message: string) => {
    return message.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const messageContent = generateMessageContent(content);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {
          <>
            {error && <Alert severity="error">{error}</Alert>}

            {successMessage && (
              <Alert severity="success"> {successMessage} </Alert>
            )}

            {(error || successMessage) && <br />}
          </>
        }
        <FormControl fullWidth>
          <TextField
            label={'Customised Message'}
            fullWidth
            value={content}
            onChange={handleContentChange}
          />
        </FormControl>
        <br /> <br />
        <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <FormControl>
            <Button type="submit" variant="contained" onClick={handleSubmit}>
              Save
            </Button>

            {uploading && <CircularProgress />}
          </FormControl>
        </div>
        <br />
        <Alert severity="info">
          <b> How it works </b>
          <br />
          <br />
          A notification is sent to customers if the associated merchant has
          notifications enabled.
          <br />
          <br />
          You can enable a merchant notifications from the merchant account
          page.
        </Alert>
      </Grid>

      <Grid item xs={12} md={6}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Typography variant="h6" fontWeight={'bold'}>
            Preview Message
          </Typography>
        </div>

        <div
          style={{
            width: '100%',
            maxWidth: 360,
            height: 640,
            margin: 'auto',
            background: '#ffffff', // Clean white background
            borderRadius: 36,
            border: `6px solid ${color}`, // Apply primary color border
            boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.2)',
            padding: 15,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            overflow: 'hidden',
          }}
        >
          {/* Phone Frame */}
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 60,
              height: 6,
              background: color, // Use primary color for the frame
              borderRadius: 10,
            }}
          ></div>

          {/* Status Bar */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 15px',
              fontSize: '12px',
              color: '#888', // Subtle color for status bar text
              fontFamily: "'Arial', sans-serif",
              position: 'absolute',
              top: 5,
              left: 15,
              right: 15,
              zIndex: 1,
            }}
          >
            <span>9:41 AM</span>
            <span>100%</span>
          </div>

          {/* Screen */}
          <div
            style={{
              backgroundColor: screenBackgroundColor, // Softer gray for screen background
              padding: 0, // Remove padding so image touches borders
              borderRadius: 20,
              height: '100%',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
              position: 'relative',
            }}
          >
            {/* Sent Message Bubble */}
            <div
              style={{
                padding: 0, // No padding so image fills the container
                backgroundColor: sentMessageColor, // Green for sent message
                borderRadius: 18,
                maxWidth: '80%',
                marginBottom: '10px',
                alignSelf: 'flex-end', // Right side of the screen
                wordWrap: 'break-word',
                fontFamily: "'Arial', sans-serif",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch', // Ensure the image fills the entire width of the bubble
                overflow: 'hidden', // Hide overflow if needed
              }}
            >
              {/* Conditionally Render Image if it exists */}
              {
                <img
                  src={logoUrl}
                  alt="sent media"
                  style={{
                    width: '100%', // Make the image take up full width of the bubble
                    height: 'auto', // Keep image's aspect ratio
                    objectFit: 'cover', // Ensure image covers the bubble area (no stretching)
                    borderRadius: '18px 18px 0 0', // Rounded corners on top
                    background: 'white',
                  }}
                />
              }
              {/* Message Text with Line Breaks */}
              <div
                style={{
                  padding: '12px 16px', // Padding only around the text
                  color: '#000',
                  wordWrap: 'break-word', // Ensure text wraps if too long
                }}
              >
                {formatMessageWithLineBreaks(messageContent) ||
                  'Your message will appear here...'}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default NotificationManager;

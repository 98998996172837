import { createContext, ReactNode, useEffect, useState } from 'react';
import { User } from '../../interfaces/common/authentication';
import { jwtDecode } from 'jwt-decode';

type Props = {
  children?: ReactNode;
};

type IAuthContext = {
  user: User | null;
  setUser: (user: User | null) => void;
};

const initialValue: IAuthContext = {
  user: null,
  setUser: () => { /* unimplemented initially */ },
};

const AuthContext = createContext<IAuthContext>(initialValue);

const AuthProvider = ({ children }: Props) => {
  //Initializing an auth state with false value (unauthenticated)
  const [user, setUser] = useState<User | null>(() => {
    // Initialize user state with the data from localStorage (if available)
    const storedUser = localStorage.getItem('pt-user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    localStorage.setItem('pt-user', JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (user) {
      const token = user.authToken;
      const { exp } = jwtDecode(token);

      if (exp) {
        const expirationTime = exp * 1000 - 60000;
        if (Date.now() >= expirationTime) {
          setUser(null);
        }
      }
    }
  });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

import { lebaneseGovernorates, regions } from "../../../data/regions";
import { distance } from 'fastest-levenshtein';


export function getClosestDistrict(apiDistrict: string) {
    console.log("google response = ", apiDistrict)
    const lowerCasedAPIDistrict = apiDistrict.toLowerCase()
    let closestMatch = lebaneseGovernorates[0]
    let minDistance = Math.min(
        distance(lowerCasedAPIDistrict, closestMatch.AR.toLowerCase()),
        distance(lowerCasedAPIDistrict, closestMatch.EN.toLowerCase()),
    )


    lebaneseGovernorates.forEach(district => {
        let dist = Math.min(
            distance(lowerCasedAPIDistrict, district.AR.toLowerCase()),
            distance(lowerCasedAPIDistrict, district.EN.toLowerCase()),
        )

        if (district.variants) {
            district.variants.forEach(variant => { dist = Math.min(dist, distance(lowerCasedAPIDistrict, variant.toLocaleLowerCase())) })
        }

        if (dist < minDistance) {
            closestMatch = district;
            minDistance = dist;
        }
    });
    return closestMatch.EN;
}

import HelpIcon from '@mui/icons-material/Help';
import { Popover, Typography } from '@mui/material';
import React from 'react';


interface GenericPopoverProps {
    display: JSX.Element
    children: React.ReactNode
}


function GenericPopover({ display, children }: GenericPopoverProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                {display}
            </div>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {children}
            </Popover>
        </>
    );
}

export default GenericPopover;

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { IFieldConfig } from './report';
import { useContext } from 'react';
import { OrgThemeContext } from '../../../contexts/theme-context';

interface ReportTableHeaderProps {
  fields_config: IFieldConfig[];
  color: string;
}

export const ReportTableHeader = ({
  fields_config,
  color,
}: ReportTableHeaderProps) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderBottomColor: color,
      backgroundColor: color,
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 48,
      fontStyle: 'bold',
      flexGrow: 1,
      textAlign: 'center',
    },
  });

  return (
    <View style={styles.container}>
      {fields_config.map((config_item, index) => (
        <Text
          key={index}
          style={{
            width: config_item.width_percentage,
            borderRightColor: color,
            borderRightWidth: 1,
            color: 'white',
          }}
        >
          {config_item.display_name.map((item) => (
            <>
              {item} {'\n'}
            </>
          ))}
        </Text>
      ))}
    </View>
  );
};

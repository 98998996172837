import {
  Chip,
  CircularProgress,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { InvoiceMetaType } from '../../../interfaces/common/invoice';
import { IInvoice } from '../invoice-builder/Invoice';
import InvoicePDFDialog from '../invoice-pdf-dialog/invoice-pdf-dialog';

import RestorePageIcon from '@mui/icons-material/RestorePage';
import ConfirmAction from '../confirm-action/confirm-action';

interface InvoicesListingTableProps {
  rows: InvoiceMetaType[];
  setRows: React.Dispatch<React.SetStateAction<InvoiceMetaType[]>>;
  isLoading: boolean;
  allowEditNote?: boolean;
  allowUndoInvoice?: boolean;
  selectedOrg?: string;
  baseEndpoint: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setResponse: React.Dispatch<
    React.SetStateAction<AxiosResponse<any, any> | undefined>
  >;
}

function InvoicesListingTable({
  rows,
  setRows,
  isLoading,
  allowEditNote,
  selectedOrg,
  baseEndpoint,
  allowUndoInvoice,
  setMessage,
  setResponse,
}: InvoicesListingTableProps) {
  const [lockedInvoiceMeta, setLockedInvoiceMeta] =
    useState<InvoiceMetaType | null>(null);
  const [lockType, setLockType] = useState<'view' | 'delete' | null>(null);
  const [confirmUndoInvoice, setConfirmUndoInvoice] = useState(false);

  const [pdfData, setPdfData] = useState<IInvoice | null>(null);

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleEditNote = async (invoice_id: string) => {
    const note = prompt('Enter new note');
    if (note === null) {
      return;
    }

    const endpoint = `${baseEndpoint}/invoice-meta/${encodeURIComponent(invoice_id)}/`;

    try {
      const response = await axios.put(endpoint, { notes: note }, config);
      const clonedRows: InvoiceMetaType[] = rows.map((invoice) =>
        invoice.invoice_id === invoice_id
          ? { ...invoice, notes: note }
          : invoice
      );
      setRows(clonedRows);
      setResponse(response);
    } catch (e) {
      if (isAxiosError(e)) {
        setResponse(e.response);
        setMessage('Failed to modify invoice note: ' + e.response?.statusText);
      } else {
        setMessage('Failed to modify invoice note');
      }
    }
  };

  const handleUndoInvoice = async (invoicemeta: InvoiceMetaType) => {
    const endpoint = `${baseEndpoint}/invoice/${encodeURIComponent(invoicemeta.invoice_id)}/`;
    try {
      await axios.delete(endpoint, config);
      setRows(rows.filter((row) => row.id !== invoicemeta.id));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setResponse(e.response);
        setMessage('Failed to undo invoice: ' + e.response?.statusText);
      } else {
        setMessage('Failed to undo invoice');
      }
    }

    setLockType(null);
    setLockedInvoiceMeta(null);
  };

  const fetchInvoiceContent = async (invoicemeta: InvoiceMetaType) => {
    setLockedInvoiceMeta(invoicemeta);
    setLockType('view');

    let endpoint = `${baseEndpoint}/invoice/${encodeURIComponent(invoicemeta.invoice_id)}/`;

    if (selectedOrg) {
      endpoint += `?org=${encodeURIComponent(selectedOrg)}`;
    }
    try {
      const response = await axios.get(endpoint, config);
      setResponse(response);
      const data: IInvoice = response.data;
      data.orders.forEach((order) => (order.order_id = order.id));
      setPdfData(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setResponse(e.response);
        setMessage(
          'Failed to download invoice content: ' + e.response?.statusText
        );
      } else {
        setMessage('Failed to download invoice content');
      }
    }
    setLockedInvoiceMeta(null);
    setLockType(null);
  };

  return (
    <>
      <TableContainer style={{ maxHeight: '80%' }}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" key={'tableCellFile'}>
                  <b>Invoice Id</b>
                </TableCell>
                <TableCell align="center" key={'tableCellType'}>
                  <b>Type</b>
                </TableCell>
                <TableCell align="center" key={'tableCellDate'}>
                  <b>Date</b>
                </TableCell>
                <TableCell align="center" key={'tableCellNotes'}>
                  <b>Notes</b>
                </TableCell>
                <TableCell align="center" key={'tableCellDownload'}>
                  <b>Download</b>
                </TableCell>
                {allowUndoInvoice && (
                  <TableCell align="center" key={'tableCellDownload'}>
                    <b>Undo Invoice</b>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((invoice, index) => (
                <TableRow key={invoice.id}>
                  <TableCell
                    align="center"
                    key={index}
                    style={{ cursor: 'pointer' }}
                  >
                    <b>{invoice.invoice_id}</b>
                  </TableCell>
                  <TableCell align="center" key={'tableCellType'}>
                    {invoice.invoice_type === 'delivery' ? (
                      <Chip
                        label="Delivery"
                        color={'success'}
                        variant="outlined"
                      />
                    ) : (
                      <Chip
                        label="Cancellation"
                        color="error"
                        variant="outlined"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center" key={'tableCellDate'}>
                    {new Date(invoice.timestamp).toLocaleString()}
                  </TableCell>
                  <TableCell align="center" key={'tableCellNotes'}>
                    {invoice.notes}

                    {allowEditNote && (
                      <IconButton
                        onClick={() => {
                          handleEditNote(invoice.invoice_id);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>

                  <TableCell align="center" key={'tableCellDownload'}>
                    <IconButton
                      onClick={() => fetchInvoiceContent(invoice)}
                      disabled={lockedInvoiceMeta !== null}
                    >
                      {lockedInvoiceMeta?.invoice_id === invoice.invoice_id &&
                        lockType === 'view' ? (
                        <CircularProgress />
                      ) : (
                        <PreviewIcon fontSize="large" />
                      )}
                    </IconButton>
                  </TableCell>
                  {allowUndoInvoice && (
                    <TableCell align="center" key={'tableCellUndo'}>
                      <IconButton
                        size="small"
                        style={{ color: 'red' }}
                        disabled={lockedInvoiceMeta !== null}
                        onClick={() => {
                          setLockType('delete')
                          setLockedInvoiceMeta(invoice)
                          setConfirmUndoInvoice(true)
                        }}
                      >
                        {lockedInvoiceMeta?.id === invoice.id &&
                          lockType === 'delete' ? (
                          <CircularProgress />
                        ) : (
                          <RestorePageIcon
                            fontSize="large"
                            style={{ color: 'red' }}
                          />
                        )}
                      </IconButton>

                      <ConfirmAction
                        message={'Are you sure you want to undo this invoice?'}
                        action={() => handleUndoInvoice(invoice)}
                        isOpen={confirmUndoInvoice && lockType === 'delete' && lockedInvoiceMeta?.id === invoice.id}
                        handleClose={() => {
                          setConfirmUndoInvoice(false)
                          setLockType(null)
                          setLockedInvoiceMeta(null)
                        }}
                        isSevereAction={true}
                        confirmSevereActionTarget="undo invoice"
                      >
                        <div>
                          Attention: This action will change the payment state
                          of the orders included in the invoice
                        </div>
                      </ConfirmAction>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {pdfData && (
        <InvoicePDFDialog pdfData={pdfData} onClose={() => setPdfData(null)} />
      )}
    </>
  );
}

export default InvoicesListingTable;

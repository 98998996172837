import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment, useContext } from 'react';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { IFieldConfig } from './report';
import { emptyOrder } from '../../delivery-company-components/orders/create-order-form/create-order-form.utils';
import { OrgThemeContext } from '../../../contexts/theme-context';

// Register the locally downloaded Arabic font
Font.register({
  family: 'Noto Sans Arabic',
  src: '/assets/fonts/Noto_Sans_Arabic/NotoSansArabic-VariableFont_wdth,wght.ttf', // Path to the font file you added
});

const styles = (color: string) =>
  StyleSheet.create({
    row: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 'auto',
      paddingTop: 6,
      paddingBottom: 6,
      borderColor: color,
      textAlign: 'center',
      fontFamily: 'Noto Sans Arabic',
    },
  });

interface ReportTableRowsProps {
  orders: OrderType[];
  fields_config: IFieldConfig[];
  color: string;
}

export const ReportTableRows = ({
  orders,
  fields_config,
  color,
}: ReportTableRowsProps) => {
  const rows = orders.map((item, rowIndex) => {
    return (
      <View style={styles(color).row} key={item.id} wrap={false}>
        {fields_config.map((config, index) => (
          <Text
            key={index}
            style={{
              width: config.width_percentage,
              borderColor: color,
              paddingLeft: 10,
              height: '100%',
              fontWeight: 'bold',
            }}
          >
            {config.get_values(item).map((value) => (
              <>
                {value} {'\n'}
              </>
            ))}
          </Text>
        ))}
      </View>
    );
  });

  rows.push(
    <View
      style={{ ...styles(color).row, backgroundColor: '#aaa' }}
      key={'total'}
      wrap={false}
    >
      {fields_config.map((config, index) => (
        <Text
          key={index}
          style={{
            width: config.width_percentage,
            borderColor: color,
            paddingLeft: 10,
            height: '100%',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          {config.get_aggregate &&
            config.get_aggregate(orders).map((value) => (
              <>
                {value} {'\n'}
              </>
            ))}
        </Text>
      ))}
    </View>
  );

  return <Fragment>{rows}</Fragment>;
};

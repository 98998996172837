import { createContext, ReactNode, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

type Props = {
    children?: ReactNode;
};

export interface PaginationConfig {
    rowsPerPage: number;
}

type IPaginationContext = {
    paginationConfig: PaginationConfig,
    setPaginationConfig: any;
};

const defaultContextVal: IPaginationContext = {
    paginationConfig: {
        rowsPerPage: 10
    },

    setPaginationConfig: () => { / * to be implemented */ }
}

const PaginationContext = createContext<IPaginationContext>(defaultContextVal);

const PaginationProvider = ({ children }: Props) => {
    //Initializing an auth state with false value (unauthenticated)
    const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>(() => {
        // Initialize user state with the data from localStorage (if available)
        const storedConfig = localStorage.getItem('pt-pagination');

        return storedConfig ? (JSON.parse(storedConfig) ?? defaultContextVal.paginationConfig)
            : defaultContextVal.paginationConfig;

    });

    useEffect(() => {
        localStorage.setItem('pt-pagination', JSON.stringify(paginationConfig));
    }, [paginationConfig]);

    return (
        <PaginationContext.Provider value={{ paginationConfig, setPaginationConfig }}>
            {children}
        </PaginationContext.Provider>
    );
};

export { PaginationContext, PaginationProvider };

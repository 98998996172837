import { Dayjs } from 'dayjs';
import { titleStyles } from './styles';

import { Text, View } from '@react-pdf/renderer';

interface TitleProps {
  organisation_id: string;
  date_from: string;
  date_to: string;
  title: string;
  subtitles?: string[];
}
export const PageTitle = ({
  date_from,
  date_to,
  organisation_id,
  title,
  subtitles,
}: TitleProps) => {
  let dates = '';

  if (date_from !== '' && date_to !== '') {
    dates = `${date_from} - ${date_to}`;
  }

  if (date_from === date_to) {
    dates = date_from;
  }

  if (dates !== '') {
    dates = `Date: ${dates}`;
  }
  return (
    <>
      <View style={{ ...titleStyles.titleContainer, marginTop: 20 }}>
        <Text style={titleStyles.reportTitle}>{title.toUpperCase()}</Text>
      </View>

      <View style={titleStyles.titleContainer}>
        <Text style={titleStyles.subtitle}>
          Organisation: {organisation_id.toUpperCase()}
        </Text>
      </View>

      {subtitles &&
        subtitles.map((subtitle) => (
          <View key={subtitle} style={titleStyles.titleContainer}>
            <Text style={titleStyles.subtitle}>{subtitle}</Text>
          </View>
        ))}

      <View style={titleStyles.titleContainer}>
        <Text style={titleStyles.subtitle}> {dates}</Text>
      </View>
    </>
  );
};

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  API_ENDPOINT,
  primaryPaletteColor,
} from '../configurations/global.config';
import { AuthContext } from './user-context/user-context';
import axios from 'axios';
import { OrganisationSettings } from '../interfaces/delivery-company/orders';

type Props = {
  children?: ReactNode;
};

type ITheme = {
  color: string;
  setColor: (c: string) => void;
};

const initialValue: ITheme = {
  color: primaryPaletteColor,
  setColor: () => {
    /* to be implemented */
  },
};

const OrgThemeContext = createContext<ITheme>(initialValue);

const OrgThemeProvider = ({ children }: Props) => {
  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const [color, setColor] = useState<string>(() => {
    // Initialize user state with the data from localStorage (if available)
    const storedColor = localStorage.getItem('pt-theme-color');
    return storedColor ?? primaryPaletteColor;
  });

  useEffect(() => {
    const getAndSetColor = async () => {
      if (user === null) return; // Make sure user is authenticated

      try {
        const response = await axios.get<OrganisationSettings>(
          `${API_ENDPOINT}/api/org/settings`,
          config
        );

        console.log('Fetching = ', response.data);

        let newColor = response.data.custom_primary_color;
        if (!newColor || newColor?.length === 0) newColor = primaryPaletteColor;

        setColor(newColor); // Update color state

        // Persist the color in localStorage
        localStorage.setItem('pt-theme-color', newColor);
      } catch (e) {
        console.error('Failed to fetch theme color:', e);
      }
    };

    // Fetch and set color if user exists
    getAndSetColor();

    // Refetch the color every 5 minutes (300000ms)
    const intervalId = setInterval(getAndSetColor, 300000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [user]); // Add `user` as a dependency to refetch when user changes

  return (
    <OrgThemeContext.Provider value={{ color, setColor }}>
      {children}
    </OrgThemeContext.Provider>
  );
};

export { OrgThemeContext, OrgThemeProvider };

import { CustomerType } from '../../../interfaces/common/customer';
import {
  BranchEffect,
  BranchType,
} from '../../../interfaces/delivery-company/branches';
import {
  CompactMerchantType,
  MerchantEffect,
  MerchantType,
} from '../../../interfaces/delivery-company/merchants';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { CustomerEffect } from '../create-customer-form/create-customer-form';

export interface assignCustomerProviderProps {
  orderData?: OrderType;
  setOrderData: React.Dispatch<React.SetStateAction<OrderType | undefined>>;
  setRegionSearchInput: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const assignCustomerProvider = ({
  orderData,
  setOrderData,
  setRegionSearchInput,
}: assignCustomerProviderProps): CustomerEffect => {
  return (value: CustomerType) => {
    if (!orderData) return;

    const newOrderData: OrderType = {
      ...orderData,
      customer: value,
    };

    if (value && value.district !== '') {
      newOrderData.district = value.district;
      newOrderData.governorate = value.governorate;
      setRegionSearchInput(value.district);
    }

    if (value) {
      newOrderData.address = value.address;
    }

    setOrderData(newOrderData);
  };
};

export interface assignMerchantProviderProps {
  orderData?: OrderType;
  setOrderData: React.Dispatch<React.SetStateAction<OrderType | undefined>>;
}

export const assignMerchantProvider = ({
  orderData,
  setOrderData,
}: assignMerchantProviderProps): MerchantEffect => {
  return (value: MerchantType) => {
    if (!orderData) return;
    const compactMerchant: CompactMerchantType = {
      id: value.id,
      name: value.name,
      phone_number: value.phone_number,
      has_usable_password: value.has_usable_password,
      default_delivery_fee_lbp: value.default_delivery_fee_lbp,
      default_delivery_fee_usd: value.default_delivery_fee_usd,
    };
    setOrderData({
      ...orderData,
      merchant: compactMerchant,
    });
  };
};

export interface assignMerchantProviderProps {
  orderData?: OrderType;
  setOrderData: React.Dispatch<React.SetStateAction<OrderType | undefined>>;
}

export const assignBranchProvider = ({
  orderData,
  setOrderData,
}: assignMerchantProviderProps): BranchEffect => {
  return (value: BranchType) => {
    if (!orderData) return;
    setOrderData({ ...orderData, branch: value });
  };
};

import CallIcon from '@mui/icons-material/Call';
import FeedIcon from '@mui/icons-material/Feed';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Alert, Button, IconButton } from '@mui/material';
import { OrderType } from '../../interfaces/delivery-company/orders';
import {
  cardHeaderStyle,
  cardStyle,
  cardTableStyle,
} from '../common/styles/grid-card.style';
import { showDeliveryState } from '../common/styles/order-states.style';
import { generateWhatsAppLink } from '../driver-components/driver-order-lookup/utils';
import { useContext } from 'react';
import { OrgThemeContext } from '../../contexts/theme-context';

function OrderInfo({ order }: { order: OrderType }) {
  const { color } = useContext(OrgThemeContext);
  return (
    <div style={cardStyle}>
      <div style={cardHeaderStyle(color)}>
        <FeedIcon />
        <div>
          <b>Order Information</b>
        </div>
      </div>

      <table style={cardTableStyle}>
        <tbody>
          <>
            {order.customer ? (
              <>
                <tr>
                  <th>Customer</th>
                  <td>
                    {order.customer.name !== ''
                      ? order.customer.name
                      : 'No Name'}
                  </td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <span>{order.customer.phone_number}</span>
                      <IconButton>
                        <a
                          href={generateWhatsAppLink(
                            order.customer.phone_number
                          )}
                        >
                          <WhatsAppIcon color="primary" />
                        </a>
                      </IconButton>
                      <IconButton>
                        <a href={`tel:${order.customer.phone_number}`}>
                          <CallIcon color="primary" />
                        </a>
                      </IconButton>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{order.address}</td>
                </tr>
              </>
            ) : (
              <Alert severity="error">Customer data not found.</Alert>
            )}
          </>
          <tr>
            <th>Merchant</th>
            <td>{order.merchant.name}</td>
          </tr>
          <tr>
            <th>State</th>
            <td>{showDeliveryState(order.delivery_state)}</td>
          </tr>
        </tbody>
      </table>

      <a href={`/order/${order.id}`}>
        <Button fullWidth color="primary" variant="contained">
          Show more
        </Button>
      </a>
    </div>
  );
}

export default OrderInfo;

import BarChartIcon from '@mui/icons-material/BarChart';
import { Alert, Card, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  invoicedDriver,
  invoicedMerchant,
  uninvoicedColor,
} from '../../../../configurations/global.config';
import {
  cardHeaderStyle,
  cardStyle,
} from '../../../common/styles/grid-card.style';
import { WidgetProps } from '../home';
import { generateMetricComponent } from '../utils';
import { useContext } from 'react';
import { OrgThemeContext } from '../../../../contexts/theme-context';

function PaymentStatesWidget({ stats, isLoading, errorMessage }: WidgetProps) {
  const { color } = useContext(OrgThemeContext);
  return (
    <Grid style={{ textAlign: 'center' }} xs={12} md={4}>
      <Card style={cardStyle}>
        <div style={cardHeaderStyle(color)}>
          <BarChartIcon />
          <div>
            <b>Payment States</b>
          </div>
        </div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {errorMessage !== '' && (
              <Alert severity="error">{errorMessage}</Alert>
            )}

            {stats !== undefined && (
              <Grid container>
                <Grid style={{ textAlign: 'center' }} xs={6} md={6}>
                  {generateMetricComponent(
                    'Uninvoiced',
                    stats.payment.UNINVOICED ?? 0,
                    uninvoicedColor
                  )}
                </Grid>

                <Grid style={{ textAlign: 'center' }} xs={6} md={6}>
                  {generateMetricComponent(
                    'Invoiced/Driver',
                    stats.payment.INVOICED_FOR_DRIVER ?? 0,
                    invoicedDriver
                  )}
                </Grid>

                <Grid style={{ textAlign: 'center' }} xs={12} md={12}>
                  {generateMetricComponent(
                    'Invoiced/Merchant',
                    stats.payment.INVOICED_FOR_MERCHANT ?? 0,
                    invoicedMerchant
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Card>
    </Grid>
  );
}

export default PaymentStatesWidget;

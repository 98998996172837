import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CreateEntityDrawer from '../../common/create-entity-drawer/create-entity-drawer';
import MerchantsListing from './merchants-listing/merchants-listing';
import CreateMerchantForm from './merchant-forms/create-or-edit-merchant';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { UserType } from '../../../enums/users';

function Merchants() {
  const { user } = useContext(AuthContext);
  const canCreateMerchant =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('add_merchant');

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Merchants
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={4}></Grid>
          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ textAlign: 'right' }}>
            <CreateEntityDrawer disabled={!canCreateMerchant}>
              <CreateMerchantForm />
            </CreateEntityDrawer>
          </Grid>
          <Grid xs={12}>
            <MerchantsListing />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Merchants;

import { useContext, useEffect, useState } from 'react';
import { Card, TablePagination } from '@mui/material';
import SearchBar from '../../../common/search-bar/search-bar';

import { MerchantType } from '../../../../interfaces/delivery-company/merchants';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import LabelKeysListingTable from './labels-listing.table';
import { LabelKeyWithValues } from '../../../../interfaces/delivery-company/labels';
import { PaginationContext } from '../../../../contexts/pagination-context';

function LabelsListing() {
    const [labelKeysList, setLabelKeysList] = useState<LabelKeyWithValues[]>([]);
    const [keysCount, setKeysCount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const searchOptions = ['Key'];

    const { paginationConfig, setPaginationConfig } = useContext(PaginationContext)

    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: paginationConfig.rowsPerPage,
        searchColumn: 'Key',
        searchValue: '',
        searchOperation: 'equals',
    });

    const { user } = useContext(AuthContext);

    const config = {
        headers: { Authorization: `Bearer ${user?.authToken}` },
    };

    useEffect(() => {
        const getData = async () => {
            let endpoint = `${API_ENDPOINT}/api/org/labelkeys/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}`;

            if (controller.searchColumn !== '' && controller.searchValue !== '') {
                endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(controller.searchValue)}`;
            }

            console.log(endpoint);
            try {
                const response = await axios.get<{
                    count: number;
                    results: LabelKeyWithValues[];
                }>(endpoint, config);
                console.log(response);

                if (response.statusText === 'OK') {
                    setLabelKeysList(response.data.results);
                    setKeysCount(response.data.count);
                } else {
                    throw new Error('Request failed');
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        setIsLoading(true);
        getData();
    }, [controller]);

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setController({
            ...controller,
            page: newPage,
        });
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setPaginationConfig({ ...paginationConfig, newRowsPerPage })
        setController({
            ...controller,
            rowsPerPage: newRowsPerPage,
            page: 0,
        });
    };

    const searchQueryAction = (
        column: string,
        value: string,
        operation: string
    ) => {
        setController({
            ...controller,
            page: 0,
            searchColumn: column,
            searchValue: value,
            searchOperation: operation,
        });
    };

    return (
        <Card sx={{ padding: 2, overflow: 'auto' }}>
            <SearchBar
                searchOptions={searchOptions}
                searchQueryAction={searchQueryAction}
            />
            <LabelKeysListingTable labelKeys={labelKeysList} isLoading={isLoading} />
            <TablePagination
                component="div"
                onPageChange={handlePageChange}
                page={controller.page}
                count={keysCount}
                rowsPerPage={controller.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
                labelRowsPerPage=""
                style={{ overflow: 'visible' }}
            />
        </Card>
    );
}

export default LabelsListing;

import {
  Alert,
  Button,
  FormControl,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useContext, useState } from 'react';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import {
  BranchType,
  CreateBranchFormProps,
} from '../../../../interfaces/delivery-company/branches';
import ConfirmAction from '../../../common/confirm-action/confirm-action';
import { emptyBranch } from './create-branch-form.utils';
import { ErrorsFlattenner } from '../../../common/errors-flattenner/errors-flattenner';

function CreateBranchForm({
  defaultBranchId,
  successCallback,
}: CreateBranchFormProps) {
  const { user } = useContext(AuthContext);

  const [branchData, setBranchData] = useState<BranchType>();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [response, setResponse] = useState<AxiosResponse>();
  const [confirmDeleteBranch, setConfirmDeleteBranch] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  React.useEffect(() => {
    const getAndSetBranch = async () => {
      if (defaultBranchId !== undefined) {
        setIsLoading(true);
        try {
          console.log(`${API_ENDPOINT}/api/v1/branch/${defaultBranchId}`);
          const response = await axios.get<BranchType>(
            `${API_ENDPOINT}/api/v1/branch/${defaultBranchId}`,
            config
          );
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setBranchData(response.data);
          }
        } catch (e) {
          setBranchData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load branch  data: ' + e.message);
          } else {
            setMessage('Failed to load branch  data');
          }
          console.log(e);
        }
        setIsLoading(false);
      } else {
        setBranchData(emptyBranch);
      }
    };
    getAndSetBranch();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (branchData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Branch...');
      const requestData = {
        name: branchData.name,
        address: branchData.address,
        organisation: user?.organisation?.pk,
        is_default: branchData.is_default,
      };

      try {
        const endpoint = `${API_ENDPOINT}/api/v1/branch/${branchData.id}/`;

        let response;

        if (defaultBranchId === undefined) {
          // create new
          response = await axios.post(
            `${API_ENDPOINT}/api/v1/branches/`,
            requestData,
            config
          );
        } else {
          // edit existing
          response = await axios.put(endpoint, requestData, config);
        }
        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Branch saved successfully');
          successCallback && successCallback(response.data);
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to save branch: ' + e.message);
        } else {
          setMessage('Failed to save branch');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteBranch = async () => {
    if (branchData) {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/v1/branch/${branchData.id}/`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setBranchData(undefined);
          setMessage('Branch deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setMessage('Failed to delete branch: ' + e.message);
        } else {
          setMessage('Failed to delete branch');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        {defaultBranchId === undefined ? 'Create Branch' : 'Edit Branch'}
      </Typography>

      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message != '' && (
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              <>
                {message}

                {response && response.status >= 300 && (
                  <ErrorsFlattenner rawErrors={response.data} />
                )}
              </>
            </Alert>
          )}

          {branchData && (
            <>
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Branch Name"
                    type="text"
                    variant="outlined"
                    required
                    value={branchData.name}
                    onChange={(e) =>
                      setBranchData({ ...branchData, name: e.target.value })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <TextField
                    label="Branch Address"
                    type="text"
                    variant="outlined"
                    required
                    value={branchData.address}
                    onChange={(e) =>
                      setBranchData({ ...branchData, address: e.target.value })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <Switch
                  checked={branchData.is_default}
                  onChange={(e) =>
                    setBranchData({
                      ...branchData,
                      is_default: !branchData.is_default,
                    })
                  }
                />
                <>Use as default branch for created orders.</>
                <br />
                <br />

                <div style={{ display: 'flex', gap: 7 }}>
                  <Button type="submit" variant="contained">
                    Save Branch
                  </Button>

                  {branchData.id !== '' && (
                    <Button
                      onClick={() => {
                        setConfirmDeleteBranch(true);
                      }}
                      variant="outlined"
                      color="error"
                    >
                      Delete Branch
                    </Button>
                  )}
                </div>
              </form>{' '}
            </>
          )}
        </>
      )}

      <ConfirmAction
        message={'Are you sure you want to delete this branch?'}
        action={deleteBranch}
        isOpen={confirmDeleteBranch}
        handleClose={() => setConfirmDeleteBranch(false)}
      >
        Attention: This action can not be undone
        <br />
        <Alert severity="info">
          Deleting this branch will not delete orders belonging to this branch.{' '}
        </Alert>
      </ConfirmAction>
    </>
  );
}

export default CreateBranchForm;

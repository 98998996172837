import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import SearchBar from '../../../common/search-bar/search-bar';

import FilterBar from '../../../common/filter-bar/filter-bar';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { useImmer } from 'use-immer';
import { DoubleDate } from '../../../common/double-date-picker/double-date-picker.interfaces';
import DoubleDatePicker from '../../../common/double-date-picker/double-date-picker';
import BulkAction from '../../../common/bulk-action/bulk-action';
import { OrderType } from '../../../../interfaces/delivery-company/orders';
import OrdersListingTable from '../../../common/orders-listing-table/orders-listing-table';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { buildQueryParams } from '../../../common/filter-bar/filter-bar.utils';
import { DefaultFilter } from '../../../common/filter-bar/filter-types';
import { PaginationContext } from '../../../../contexts/pagination-context';
import { OrgThemeContext } from '../../../../contexts/theme-context';
import { SortingByColumnState } from '../../../common/orders-listing-table/utils';

function MerchantOrdersListing() {
  const [ordersList, setOrdersList] = useState<OrderType[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { color } = useContext(OrgThemeContext);

  const [selectedOrders, updateSelectedOrders] = useImmer<
    Map<string, OrderType>
  >(new Map());

  const resetSelectionState = () => {
    updateSelectedOrders((selectedOrders) => selectedOrders.clear());
  };

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const { paginationConfig, setPaginationConfig } =
    useContext(PaginationContext);
  const totalSelected = selectedOrders.size;

  const searchOptions = [
    'Order_Id',
    'Ref_Number',
    'Customer_Name',
    'Customer_Phone',
  ];
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPage,
    searchColumn: '',
    searchValue: '',
    searchOperation: 'equals',
  });

  const [filters, setFilters] = useState<DefaultFilter[]>([]);
  const [sorting, setSorting] = useState<SortingByColumnState>({
    column: 'creation_date',
    order: 'decreasing',
  });
  useEffect(() => {
    const getData = async () => {
      const relevant_fields = [
        'id',
        'order_id',
        'reference_id',
        'creation_date',
        'merchant',
        'customer',
        'address',
        'district',
        'governorate',
        'price_usd',
        'price_lbp',
        'delivery_fee_lbp',
        'delivery_fee_usd',
        'number_of_packages',
        'delivery_state',
        'payment_state',
        'branch',
        'tracking_hash',
      ];

      let endpoint = `${API_ENDPOINT}/api/v1/orders/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}&fields=${relevant_fields.join(',')}`;

      if (controller.searchColumn !== '' && controller.searchValue !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(controller.searchValue)}`;
      }

      const filterQueryParams = buildQueryParams(filters);
      endpoint += `${filterQueryParams}`;

      const { startDate, endDate } = dateFilter;

      if (startDate !== null) {
        endpoint += `&date${dateFilter.operation === 'assigned' ? '_assignment' : ''}_from=${encodeURIComponent(startDate.format('YYYY-MM-DD'))}`;
      }

      if (endDate !== null) {
        endpoint += `&date${dateFilter.operation === 'assigned' ? '_assignment' : ''}_to=${encodeURIComponent(endDate.format('YYYY-MM-DD'))}`;
      }
      endpoint += `&sort_by_${sorting.order}=${encodeURIComponent(sorting.column)}`;

      try {
        console.log(endpoint);
        const response = await axios.get<{
          count: number;
          results: OrderType[];
        }>(endpoint, config);
        console.log(response);
        if (response.statusText === 'OK') {
          setOrdersList(response.data.results);
          setOrdersCount(response.data.count);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [controller, dateFilter, filters, sorting]);

  const buildOrdersFilters = async (query: string) => {
    if (query === '') {
      return [];
    }

    const endpoint = `${API_ENDPOINT}/api/merchant/orders/filters/?query=${encodeURIComponent(query)}`;
    const filters: DefaultFilter[] = [];
    try {
      const response = await axios.get<DefaultFilter[]>(endpoint, config);
      console.log(response);

      if (response.statusText === 'OK') {
        console.log('response data = ', response.data);
        response.data.forEach((item: DefaultFilter) => {
          filters.push(new DefaultFilter(item.key, item.value, item.labelId));
        });
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.log(error);
    }
    return filters;
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationConfig({ ...paginationConfig, rowsPerPage: newRowsPerPage });
    setController({
      ...controller,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      page: 0,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  const resetPaginationControls = () => {
    setController({
      ...controller,
      page: 0,
    });
  };
  const filterQueryAction = (filters: DefaultFilter[]) => {
    resetPaginationControls();
    setFilters(filters);
    resetSelectionState();
  };

  const dateFilterAction = (dateFilterValue: DoubleDate) => {
    resetPaginationControls();
    setDateFilter(dateFilterValue);
  };

  const resetAll = () => {
    resetPaginationControls();
    resetSelectionState();
  };

  return (
    <Box>
      {
        <Card sx={{ padding: 2 }}>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{ textAlign: 'center', mt: 1, mb: 1 }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                  }}
                >
                  <ManageSearchIcon fontSize="large" color="primary" />
                  <span>
                    <b>Search and Filter Controls</b>
                  </span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SearchBar
                searchOptions={searchOptions}
                searchQueryAction={searchQueryAction}
              />
              <br />

              <DoubleDatePicker dateFilterAction={dateFilterAction} />

              <br />

              <FilterBar<DefaultFilter>
                currentlySelectedFilters={filters}
                filterQueryAction={filterQueryAction}
                handleFetchFilters={buildOrdersFilters}
                helpWidget={
                  <div>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      color={color}
                    >
                      <b>Available Filters</b>
                    </Typography>

                    <TableContainer component={Paper} elevation={0}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>Filter By</b>
                            </TableCell>
                            <TableCell>
                              <b>Examples</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Delivery State</TableCell>
                            <TableCell style={{ color: color }}>
                              <b>delivery_state:pending</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Payment State</TableCell>
                            <TableCell style={{ color: color }}>
                              <b>payment_state:uninvoiced</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Region</TableCell>
                            <TableCell style={{ color: color }}>
                              <b>
                                district:akkar
                                <br />
                                governorate:north
                              </b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Labels</TableCell>
                            <TableCell style={{ color: color }}>
                              <b>
                                category: glass
                                <br />
                                category: * (matches all category values)
                              </b>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                }
              />
            </AccordionDetails>
          </Accordion>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
              marginLeft: 10,
            }}
          >
            <Chip
              icon={<ChecklistIcon />}
              color="primary"
              label={`Selected ${totalSelected}/${ordersCount}`}
              variant={
                totalSelected === ordersCount && totalSelected > 0
                  ? 'filled'
                  : 'outlined'
              }
            />

            <BulkAction
              selectedOrders={selectedOrders}
              bulkEndpoint={`${API_ENDPOINT}/api/merchant/orders/bulk-update/`}
              refetchOrders={resetAll}
            />
          </div>

          <OrdersListingTable
            orders={ordersList}
            selectedOrders={selectedOrders}
            updateSelectedOrders={updateSelectedOrders}
            isLoading={isLoading}
            sorting={sorting}
            setSorting={setSorting}
            generateOrderRoute={(order: OrderType) => {
              return `/org/${user?.organisation?.org_id}/order/${order.id}`;
            }}
            actionable={true}
          />

          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={controller.page}
            count={ordersCount}
            rowsPerPage={controller.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
            labelRowsPerPage=""
            style={{ overflow: 'visible' }}
          />
        </Card>
      }
    </Box>
  );
}

export default MerchantOrdersListing;

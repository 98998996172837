import { NotificationMode } from "../enums/notifications";
import EmailIcon from '@mui/icons-material/Email';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SmsIcon from '@mui/icons-material/Sms';

export const display_notification_mode = (mode: NotificationMode) => {

    let icon = null

    if (mode == NotificationMode.EMAIL) icon = <EmailIcon color="primary" />
    else if (mode == NotificationMode.WHATSAPP) icon = < WhatsappIcon color="primary" />
    else if (mode == NotificationMode.SMS) icon = <SmsIcon color="primary" />
    else icon = <DoDisturbIcon color="primary" />


    return <div style={{ display: "flex", flexDirection: "row", gap: 4, justifyContent: "center" }}>
        {icon}
        <div>{mode}</div>
    </div>

}
import {
  Checkbox,
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import LinkIcon from '@mui/icons-material/Link';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useContext } from 'react';
import { Updater } from 'use-immer';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { DeliveryState } from '../../../enums/orders';
import { UserType } from '../../../enums/users';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CopyLinkButton from '../copy-link-button/copy-link-button';
import {
  showDeliveryState,
  showPaymentState,
} from '../styles/order-states.style';
import CustomerLinkOrPopover from '../customer-link-or-popover/customer-link-or-popover';
import { get_cod } from '../../../utils/cod';
import { downArrow, SortingByColumnState, upArrow } from './utils';

interface CenteredTableCellProps {
  children: React.ReactNode;
  cursor?: boolean;
  padding?: string;
}
function CenteredTableCell({
  children,
  cursor,
  padding,
}: CenteredTableCellProps) {
  return (
    <TableCell
      align="center"
      style={{ cursor: cursor ? 'pointer' : '', padding: padding }}
    >
      {children}
    </TableCell>
  );
}

interface OrdersListingTableProps {
  orders: OrderType[];
  selectedOrders: Map<string, OrderType>;
  updateSelectedOrders: Updater<Map<string, OrderType>>;
  isLoading: boolean;
  actionable?: boolean;
  generateOrderRoute?: (order: OrderType) => string;
  sorting?: SortingByColumnState;
  setSorting?: any;
}

function OrdersListingTable({
  orders,
  selectedOrders,
  sorting,
  setSorting,
  updateSelectedOrders,
  isLoading,
  actionable,
  generateOrderRoute,
}: OrdersListingTableProps) {
  const toggleIdSelection = (order: OrderType) => {
    updateSelectedOrders((draft) => {
      const rowId = order.id;

      if (draft.has(rowId)) draft.delete(rowId);
      else draft.set(rowId, order);
    });
  };

  const selectAllRows = (orders: OrderType[]) => {
    updateSelectedOrders((draft) => {
      orders.forEach((order) => draft.set(order.id, order));
    });
  };

  const unSelectAllRows = (orders: OrderType[]) => {
    updateSelectedOrders((draft) => {
      orders.forEach((order) => draft.delete(order.id));
    });
  };

  const currentIds = orders.map((order) => order.id);

  const numSelected = currentIds.reduce(
    (result: number, orderId: string) =>
      selectedOrders.has(orderId) ? result + 1 : result,
    0
  );
  const rowCount = orders.length;
  const allSelected = numSelected === rowCount;

  const { user } = useContext(AuthContext);

  if (user === null) return <div>Unauthenticated</div>;
  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {actionable && (
                <CenteredTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      (allSelected ||
                        (rowCount > 0 && numSelected === rowCount)) &&
                      numSelected > 0
                    }
                    onChange={(e) => {
                      if (allSelected) unSelectAllRows(orders);
                      else selectAllRows(orders);
                    }}
                  />
                </CenteredTableCell>
              )}

              <CenteredTableCell>
                {' '}
                <b>Order Id</b>
              </CenteredTableCell>
              <CenteredTableCell>
                <b>Ref #</b>
              </CenteredTableCell>
              <CenteredTableCell>
                {sorting?.column === 'creation_date' ? (
                  <div
                    onClick={() =>
                      setSorting({
                        ...sorting,
                        order:
                          sorting.order === 'increasing'
                            ? 'decreasing'
                            : 'increasing',
                      })
                    }
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <b>
                      Creation Date
                      {sorting.order === 'increasing' ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </b>
                  </div>
                ) : (
                  <>
                    <b>Creation Date</b>
                  </>
                )}
              </CenteredTableCell>
              {user.type !== UserType.MERCHANT && (
                <CenteredTableCell>
                  <b>Merchant</b>
                </CenteredTableCell>
              )}
              <CenteredTableCell>
                <b>Customer</b>
              </CenteredTableCell>
              {user.type !== UserType.MERCHANT &&
                user.type !== UserType.DRIVER && (
                  <CenteredTableCell>
                    <b>Driver</b>
                  </CenteredTableCell>
                )}
              <CenteredTableCell>
                <b>Address</b>
              </CenteredTableCell>
              <CenteredTableCell>
                <b>Region</b>
              </CenteredTableCell>
              <CenteredTableCell>
                <b>COD</b>
              </CenteredTableCell>
              {
                <CenteredTableCell>
                  <b>
                    {user.type === UserType.DRIVER
                      ? 'Commission'
                      : 'Delivery Fee'}
                  </b>
                </CenteredTableCell>
              }

              <CenteredTableCell>
                <b>Status</b>
              </CenteredTableCell>
              <CenteredTableCell>
                <b>Branch</b>
              </CenteredTableCell>
              <CenteredTableCell>
                <b>Tracking Link</b>
              </CenteredTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.order_id}>
                {actionable && (
                  <CenteredTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedOrders.has(order.id)}
                      onChange={(e) => {
                        toggleIdSelection(order);
                      }}
                    />
                  </CenteredTableCell>
                )}

                <CenteredTableCell cursor={true}>
                  <a
                    href={
                      generateOrderRoute === undefined
                        ? `/order/${order.id}`
                        : generateOrderRoute(order)
                    }
                  >
                    <b>
                      {order.delivery_state ===
                      DeliveryState.PENDING_APPROVAL ? (
                        <>
                          <Chip
                            label={<b>{order.order_id || 'NEW'}</b>}
                            variant="filled"
                            style={{
                              margin: 1,
                              backgroundColor: 'rgba(200, 245, 0)',
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {order.order_id || (
                            <>
                              <QuestionMarkIcon />
                              <QuestionMarkIcon />
                            </>
                          )}
                        </>
                      )}
                    </b>
                  </a>
                </CenteredTableCell>
                <CenteredTableCell>{order.reference_id}</CenteredTableCell>
                <CenteredTableCell>
                  {order.creation_date !== undefined &&
                    new Date(order.creation_date).toLocaleString()}
                </CenteredTableCell>

                {user.type !== UserType.MERCHANT && (
                  <CenteredTableCell cursor={true}>
                    <a href={`/merchant/${order.merchant.id}`}>
                      <b>{order.merchant.name}</b>
                    </a>
                  </CenteredTableCell>
                )}

                <CenteredTableCell cursor={true}>
                  <CustomerLinkOrPopover customer={order.customer} />
                </CenteredTableCell>
                {user.type !== UserType.MERCHANT &&
                  user.type !== UserType.DRIVER && (
                    <CenteredTableCell cursor={order.driver !== null}>
                      <a href={`/driver/${order.driver?.id}`}>
                        <b>{order.driver && order.driver.name}</b>
                      </a>
                    </CenteredTableCell>
                  )}
                <CenteredTableCell>{order.address}</CenteredTableCell>
                <CenteredTableCell>
                  {order.governorate}/{order.district}
                </CenteredTableCell>

                <CenteredTableCell>
                  {get_cod(order).usd.toString()} $ <br />
                  {get_cod(order).lbp.toString()} L.L
                </CenteredTableCell>
                <CenteredTableCell>
                  {user.type === UserType.DRIVER ? (
                    <>
                      {order.driver_commission_usd.toString()} $ <br />
                      {order.driver_commission_usd.toString()} L.L
                    </>
                  ) : (
                    <>
                      {order.delivery_fee_usd.toString()} $ <br />
                      {order.delivery_fee_lbp.toString()} L.L
                    </>
                  )}
                </CenteredTableCell>
                <CenteredTableCell>
                  {showDeliveryState(order.delivery_state)}
                  <br /> <br />
                  {showPaymentState(order.payment_state)}
                </CenteredTableCell>
                <CenteredTableCell cursor={order.branch !== null}>
                  {user.type === UserType.MERCHANT ? (
                    order.branch?.name
                  ) : (
                    <a href={`/branch/${order.branch?.id}`}>
                      <b>{order.branch?.name}</b>
                    </a>
                  )}
                </CenteredTableCell>
                <CenteredTableCell>
                  <CopyLinkButton
                    icon={<LinkIcon color="primary" />}
                    link={
                      window.location.origin +
                      '/tracking/' +
                      order.tracking_hash
                    }
                  />
                </CenteredTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default OrdersListingTable;

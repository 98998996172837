import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { SearchBarProps } from './search-bar.interfaces';

import BackspaceIcon from '@mui/icons-material/Backspace';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useState } from 'react';
import { OrgThemeContext } from '../../../contexts/theme-context';
const SearchBar = ({ searchOptions, searchQueryAction }: SearchBarProps) => {
  const [searchOption, setSearchOption] = useState(searchOptions[0]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOperation, setSearchOperation] = useState('equals');
  const { color } = useContext(OrgThemeContext);

  return (
    <form>
      <Box
        component="fieldset"
        border={'none'}
        borderTop={'1px solid rgb(0,0,0,0.1)'}
      >
        <legend>
          <b>Search Controls</b>
        </legend>
        <Grid container spacing={1}>
          <Grid xs={6} md={2}>
            <FormControl fullWidth>
              <InputLabel id="search-select-label">Search By</InputLabel>

              <Select
                value={searchOption}
                label="Search By"
                id="search-select-label"
                onChange={(e) => setSearchOption(e.target.value)}
              >
                {searchOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={6} md={2}>
            <FormControl fullWidth>
              <InputLabel id="search-select-label">Condition</InputLabel>
              <Select
                value={searchOperation}
                label="Operation Type"
                id="search-select-label"
                onChange={(e) => setSearchOperation(e.target.value)}
              >
                <MenuItem key={'equals'} value={'equals'}>
                  equals
                </MenuItem>

                <MenuItem key={'contains'} value={'contains'}>
                  contains
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={6} md={4}>
            <FormControl fullWidth>
              <TextField
                id="search-bar"
                className="text"
                value={searchQuery}
                onChange={(e) => {
                  if (e.target) setSearchQuery(e.target.value);
                }}
                label={`Enter ${searchOption}`}
                variant="outlined"
                placeholder={`Enter ${searchOption} `}
              />
            </FormControl>
          </Grid>

          <Grid xs={6} md={1}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <IconButton
                type="submit"
                aria-label="search"
                style={{ flexGrow: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  searchQueryAction(searchOption, searchQuery, searchOperation);
                }}
              >
                <SearchIcon style={{ fill: `${color} ` }} />
              </IconButton>

              <IconButton
                type="submit"
                aria-label="search"
                style={{ flexGrow: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  setSearchOption('');
                  setSearchQuery('');
                  searchQueryAction('', '', 'equals');
                }}
              >
                <BackspaceIcon style={{ fill: `${color} ` }} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default SearchBar;

import { Box, TablePagination } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { API_ENDPOINT } from '../../../../../configurations/global.config';
import { AuthContext } from '../../../../../contexts/user-context/user-context';
import { InvoiceMetaType } from '../../../../../interfaces/common/invoice';
import DoubleDatePicker from '../../../../common/double-date-picker/double-date-picker';
import { DoubleDate } from '../../../../common/double-date-picker/double-date-picker.interfaces';
import InvoicesListingTable from '../../../../common/invoices-listing-table/invoices-listing-table';
import { UserType } from '../../../../../enums/users';
import { PaginationContext } from '../../../../../contexts/pagination-context';

interface InvoicesListingProps {
  driverId: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setResponse: React.Dispatch<
    React.SetStateAction<AxiosResponse<any, any> | undefined>
  >;
}

function DriverInvoicesListing({
  driverId,
  setMessage,
  setResponse,
}: InvoicesListingProps) {
  const [invoicesList, setInvoicesList] = useState<InvoiceMetaType[]>([]);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const { paginationConfig, setPaginationConfig } =
    useContext(PaginationContext);

  const [paginationController, setPaginationController] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPage,
  });

  const canChangeInvoice =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('change_invoicemeta');

  const canDeleteInvoice =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('delete_invoicemeta');

  useEffect(() => {
    let response: any;
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/org/invoice-metas/?page=${paginationController.page + 1}&page_size=${paginationController.rowsPerPage}&driver_id=${encodeURIComponent(driverId)}`;

      const { startDate, endDate } = dateFilter;
      if (startDate !== null) {
        endpoint += `&date${dateFilter.operation === 'assigned' ? '_assignment' : ''}_from=${encodeURIComponent(startDate.format('YYYY-MM-DD'))}`;
      }

      if (endDate !== null) {
        endpoint += `&date${dateFilter.operation === 'assigned' ? '_assignment' : ''}_to=${encodeURIComponent(endDate.format('YYYY-MM-DD'))}`;
      }

      try {
        response = await axios.get<{
          count: number;
          results: InvoiceMetaType[];
        }>(endpoint, config);

        setResponse(response);
        setInvoicesList(response.data.results);
        setInvoicesCount(response.data.count);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to fetch invoices: ' + e.response?.statusText);
        } else {
          setMessage('Failed to fetch invoices');
        }
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [dateFilter, paginationController, driverId]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPaginationController({
      ...paginationController,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationConfig({ ...paginationConfig, rowsPerPage: newRowsPerPage });
    setPaginationController({
      ...paginationController,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });
  };

  const resetPaginationController = () => {
    setPaginationController({
      ...paginationController,
      page: 0,
    });
  };

  const dateFilterAction = (dateFilterValue: DoubleDate) => {
    resetPaginationController();
    setDateFilter(dateFilterValue);
  };

  return (
    <Box>
      <DoubleDatePicker dateFilterAction={dateFilterAction} />

      <br />
      <br />

      <InvoicesListingTable
        allowEditNote={canChangeInvoice}
        rows={invoicesList}
        allowUndoInvoice={canDeleteInvoice}
        setRows={setInvoicesList}
        isLoading={isLoading}
        baseEndpoint={`${API_ENDPOINT}/api/org/driver/${driverId}`}
        setResponse={setResponse}
        setMessage={setMessage}
      />

      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={paginationController.page}
        count={invoicesCount}
        rowsPerPage={paginationController.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
        labelRowsPerPage=""
        style={{ overflow: 'visible' }}
      />
    </Box>
  );
}

export default DriverInvoicesListing;

import { useContext, useEffect, useState } from 'react';
import { Box, Card, TablePagination } from '@mui/material';
import SearchBar from '../../../common/search-bar/search-bar';
import BranchesListingTable from './branches-listing.table';
import { BranchType } from '../../../../interfaces/delivery-company/branches';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { PaginationContext } from '../../../../contexts/pagination-context';

function BranchesListing() {
  const [branchesList, setBranchesList] = useState<BranchType[]>([]);
  const [branchesCount, setBranchesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const searchOptions = ['Name', 'Address'];
  const { paginationConfig, setPaginationConfig } =
    useContext(PaginationContext);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPage,
    searchColumn: 'Name',
    searchValue: '',
    searchOperation: 'equals',
  });

  useEffect(() => {
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/v1/branches/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}`;

      if (controller.searchColumn !== '' && controller.searchValue !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${controller.searchValue}`;
      }

      const config = {
        headers: { Authorization: `Bearer ${user?.authToken}` },
      };

      console.log('config: ', config);

      console.log(endpoint);
      try {
        const response = await axios.get<{
          count: number;
          results: BranchType[];
        }>(endpoint, config);
        console.log(response);

        if (response.statusText === 'OK') {
          setBranchesList(response.data.results);
          setBranchesCount(response.data.count);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [controller]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationConfig({ ...paginationConfig, rowsPerPage: newRowsPerPage });
    setController({
      ...controller,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      page: 0,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  return (
    <Box>
      <Card sx={{ padding: 2 }}>
        <SearchBar
          searchOptions={searchOptions}
          searchQueryAction={searchQueryAction}
        />
        <BranchesListingTable branches={branchesList} isLoading={isLoading} />

        <TablePagination
          component="div"
          onPageChange={handlePageChange}
          page={controller.page}
          count={branchesCount}
          rowsPerPage={controller.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
          labelRowsPerPage=""
          style={{ overflow: 'visible' }}
        />
      </Card>
    </Box>
  );
}

export default BranchesListing;

import Decimal from 'decimal.js';
import { emptyMerchant } from '../../../../data/merchants';
import { DeliveryState, PaymentState } from '../../../../enums/orders';
import { OrderType } from '../../../../interfaces/delivery-company/orders';

export const emptyOrder: OrderType = {
  id: '',
  order_id: '',
  reference_id: '',
  customer: null,
  address: '',
  district: '',
  governorate: '',
  branch: null,
  driver: null,
  price_usd: Decimal(0),
  price_lbp: Decimal(0),
  delivery_fee_usd: Decimal(0),
  delivery_fee_lbp: Decimal(0),
  driver_commission_lbp: Decimal(0),
  driver_commission_usd: Decimal(0),
  merchant: emptyMerchant,
  number_of_packages: 1,
  description: '',
  delivery_state: DeliveryState.PENDING_APPROVAL,
  payment_state: PaymentState.UNINVOICED,
  tracking_hash: '',
  private_note: '',
  public_note: '',
  driver_note: '',
  delivery_fee_covered_by_merchant: false,
};

import Decimal from 'decimal.js';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { parseFloatWithPrecision } from '../../../utils/decimal';
import { IFieldConfig } from './report';
import {
  compute_total_commission_lbp,
  compute_total_commission_usd,
  compute_total_delivery_fee_lbp,
  compute_total_delivery_fee_usd,
  compute_total_price_lbp,
  compute_total_price_usd,
} from './utils';

export const defaultOrgReportFieldsConfig: IFieldConfig[] = [
  {
    display_name: ['Order Id', '(Ref #)'],
    width_percentage: '20%',
    get_values: (order: OrderType) => {
      const vals = [`${order.order_id}`];
      order.reference_id && vals.push(`(${order.reference_id})`);
      return vals;
    },
    get_aggregate: (orders: OrderType[]) => [
      'Total',
      `${orders.length} orders`,
    ],
  },
  {
    display_name: ['Customer'],
    width_percentage: '23%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
    ],
  },
  {
    display_name: ['Address'],
    width_percentage: '30%',
    get_values: (order: OrderType) => [order.address || ''],
  },
  {
    display_name: ['Price'],
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      parseFloatWithPrecision(order.price_usd.toString()).toFixed(2) + '$',
      parseFloatWithPrecision(order.price_lbp.toString()) + 'L.L',
    ],
    get_aggregate: (orders: OrderType[]) => [
      `${compute_total_price_usd(orders)} $`,
      `${compute_total_price_lbp(orders)} L.L`,
    ],
  },
  {
    display_name: ['Del. Fee'],
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      parseFloatWithPrecision(order.delivery_fee_usd.toString()).toFixed(2) +
        '$',
      parseFloatWithPrecision(order.delivery_fee_lbp.toString()) + 'L.L',
    ],
    get_aggregate: (orders: OrderType[]) => [
      `${compute_total_delivery_fee_usd(orders)} $`,
      `${compute_total_delivery_fee_lbp(orders)} L.L`,
    ],
  },
  {
    display_name: ['Commission'],
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      parseFloatWithPrecision(order.driver_commission_usd.toString()).toFixed(
        2
      ) + '$',
      parseFloatWithPrecision(order.driver_commission_lbp.toString()) + 'L.L',
    ],
    get_aggregate: (orders: OrderType[]) => [
      `${compute_total_commission_usd(orders)} $`,
      `${compute_total_commission_lbp(orders)} L.L`,
    ],
  },
];

export const defaultDriverReportFieldsConfig: IFieldConfig[] = [
  {
    display_name: ['Order Id', '(Ref #)'],
    width_percentage: '19%',
    get_values: (order: OrderType) => {
      const vals = [order.order_id];
      order.reference_id && vals.push(`(${order.reference_id})`);
      return vals;
    },
    get_aggregate: (orders: OrderType[]) => [
      'Total',
      `${orders.length} orders`,
    ],
  },
  {
    display_name: ['Customer'],
    width_percentage: '19%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
      order.district,
    ],
  },
  {
    display_name: ['Merchant'],
    width_percentage: '16%',
    get_values: (order: OrderType) => [order.merchant?.name ?? ''],
  },
  {
    display_name: ['Commission'],
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      parseFloatWithPrecision(order.driver_commission_usd.toString()).toFixed(
        2
      ) + '$',
      parseFloatWithPrecision(order.driver_commission_lbp.toString()) + 'L.L',
    ],
    get_aggregate: (orders: OrderType[]) => [
      `${compute_total_commission_usd(orders)} $`,
      `${compute_total_commission_lbp(orders)} L.L`,
    ],
  },
  {
    display_name: ['COD'],
    width_percentage: '15%',
    get_values: (order: OrderType) => {
      let collected_delivery_fee_usd = Decimal(0);
      let collected_delivery_fee_lbp = Decimal(0);
      if (!order.delivery_fee_covered_by_merchant) {
        collected_delivery_fee_usd = order.delivery_fee_usd;
        collected_delivery_fee_lbp = order.delivery_fee_lbp;
      }
      return [
        parseFloatWithPrecision(order.price_usd.toString())
          .add(parseFloatWithPrecision(collected_delivery_fee_usd.toString()))
          .toFixed(2) + '$',
        parseFloatWithPrecision(order.price_lbp.toString()).add(
          parseFloatWithPrecision(collected_delivery_fee_lbp.toString())
        ) + 'L.L',
      ];
    },
    get_aggregate: (orders: OrderType[]) => {
      const total_price_usd = compute_total_price_usd(orders);
      const total_price_lbp = compute_total_price_lbp(orders);
      const total_delivery_usd = compute_total_delivery_fee_usd(
        orders.filter((item) => !item.delivery_fee_covered_by_merchant)
      );
      const total_delivery_lbp = compute_total_delivery_fee_lbp(
        orders.filter((item) => !item.delivery_fee_covered_by_merchant)
      );
      const total_usd = total_price_usd.add(total_delivery_usd);
      const total_lbp = total_price_lbp.add(total_delivery_lbp);
      return [`${total_usd} $`, `${total_lbp} L.L`];
    },
  },
  {
    display_name: ['Notes'],
    width_percentage: '16%',
    get_values: (order: OrderType) => [order.public_note],
  },
];

export const defaultMerchantReportFieldsConfig: IFieldConfig[] = [
  {
    display_name: ['Order Id', 'Ref #'],
    width_percentage: '17%',
    get_values: (order: OrderType) => {
      const vals = [`${order.order_id}`];
      order.reference_id && vals.push(`(${order.reference_id})`);
      return vals;
    },
    get_aggregate: (orders: OrderType[]) => [
      'Total',
      `${orders.length} orders`,
    ],
  },
  {
    display_name: ['Customer'],
    width_percentage: '18%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
      order.district,
    ],
  },
  {
    display_name: ['Delivery', 'due customer'],
    width_percentage: '15%',
    get_values: (order: OrderType) => {
      if (order.delivery_fee_covered_by_merchant) {
        return ['0 $', '0 L.L'];
      }
      return [
        parseFloatWithPrecision(order.delivery_fee_usd.toString()).toFixed(2) +
          '$',
        parseFloatWithPrecision(order.delivery_fee_lbp.toString()) + 'L.L',
      ];
    },
    get_aggregate: (orders: OrderType[]) => {
      return [
        `${compute_total_delivery_fee_usd(orders.filter((item) => !item.delivery_fee_covered_by_merchant))} $`,
        `${compute_total_delivery_fee_lbp(orders.filter((item) => !item.delivery_fee_covered_by_merchant))} L.L`,
      ];
    },
  },
  {
    display_name: ['Delivery', 'due merchant'],
    width_percentage: '15%',
    get_values: (order: OrderType) => {
      if (!order.delivery_fee_covered_by_merchant) {
        return ['0 $', '0 L.L'];
      }
      console.log('Test: ', order.delivery_fee_covered_by_merchant);
      return [
        parseFloatWithPrecision(order.delivery_fee_usd.toString()).toFixed(2) +
          '$',
        parseFloatWithPrecision(order.delivery_fee_lbp.toString()) + 'L.L',
      ];
    },
    get_aggregate: (orders: OrderType[]) => {
      return [
        `${compute_total_delivery_fee_usd(orders.filter((item) => item.delivery_fee_covered_by_merchant))} $`,
        `${compute_total_delivery_fee_lbp(orders.filter((item) => item.delivery_fee_covered_by_merchant))} L.L`,
      ];
    },
  },
  {
    display_name: ['Price'],
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      parseFloatWithPrecision(order.price_usd.toString()).toFixed(2) + '$',
      parseFloatWithPrecision(order.price_lbp.toString()) + 'L.L',
    ],
    get_aggregate: (orders: OrderType[]) => {
      return [
        `${compute_total_price_usd(orders)} $`,
        `${compute_total_price_lbp(orders)} L.L`,
      ];
    },
  },
  {
    display_name: ['Notes'],
    width_percentage: '22%',
    get_values: (order: OrderType) => [order.public_note],
  },
];

import Decimal from "decimal.js";
import { OrderType } from "../interfaces/delivery-company/orders";
import { parseFloatWithPrecision } from "./decimal";

interface COD {
    usd: Decimal;
    lbp: Decimal;
}
export const get_cod = (order: OrderType): COD => {
    const price_usd = parseFloatWithPrecision(order.price_usd.toString())
    const price_lbp = parseFloatWithPrecision(order.price_lbp.toString())
    const delivery_usd = parseFloatWithPrecision(order.delivery_fee_usd.toString())
    const delivery_lbp = parseFloatWithPrecision(order.delivery_fee_lbp.toString())
    return order.delivery_fee_covered_by_merchant ? {
        usd: price_usd,
        lbp: price_lbp
    } : {
        usd: price_usd.add(delivery_usd),
        lbp: price_lbp.add(delivery_lbp),
    }
}
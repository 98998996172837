import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CustomerType } from '../../../../interfaces/common/customer';

interface CustomersListingTableProps {
  customers: CustomerType[];
  isLoading: boolean;
}

function CustomersListingTable({
  customers,
  isLoading,
}: CustomersListingTableProps) {
  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Phone</b>
              </TableCell>
              <TableCell align="center">
                <b>Address</b>
              </TableCell>
              <TableCell align="center">
                <b>Region</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell align="center" style={{ cursor: 'pointer' }}>
                  <a href={`/customer/${customer.id}`}>
                    <b>{customer.name !== "" ? customer.name : "No Name"}</b>
                  </a>
                </TableCell>
                <TableCell align="center">{customer.phone_number}</TableCell>
                <TableCell align="center">{customer.address}</TableCell>
                <TableCell align="center">
                  {customer.governorate}/{customer.district}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default CustomersListingTable;

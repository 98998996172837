import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { IFieldConfig, IFooterRow } from './report';
import { ReportTableFooter } from './table-footer';
import { ReportTableHeader } from './table-header';
import { ReportTableRows } from './table-rows';
import { useContext } from 'react';
import { OrgThemeContext } from '../../../contexts/theme-context';

interface TableSeparatorProps {
  title: string;
  color: string;
}

const TableSeparator = ({ title, color }: TableSeparatorProps) => {
  return (
    <View
      style={{
        letterSpacing: 1,
        fontSize: 13,
        textAlign: 'center',
        textTransform: 'uppercase',
        backgroundColor: color,
        width: '100%',
      }}
    >
      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {title}
      </Text>
    </View>
  );
};

interface ReportTableProps {
  orders: OrderType[];
  fields_config: IFieldConfig[];
  title: string;
  on_new_page: boolean;
  header_bg: string;
  table_footer_rows: IFooterRow[];
  color: string;
}

export const ReportTable = ({
  orders,
  fields_config,
  title,
  header_bg,
  color,
  table_footer_rows,
}: ReportTableProps) => {
  const styles = (color: string) =>
    StyleSheet.create({
      tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: color,
        width: '100%',
      },
    });
  return (
    <View style={styles(color).tableContainer}>
      <TableSeparator title={title} color={header_bg} />
      <ReportTableHeader fields_config={fields_config} color={color} />
      <ReportTableRows
        orders={orders}
        fields_config={fields_config}
        color={color}
      />
      <ReportTableFooter
        orders={orders}
        color={color}
        table_footer_rows={table_footer_rows}
      />
    </View>
  );
};

import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import MuiPhoneNumber from 'mui-phone-number';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../configurations/global.config';
import { AuthContext } from '../../contexts/user-context/user-context';
import { UserType } from '../../enums/users';
import { LoginFormData, User } from '../../interfaces/common/authentication';
import { VerifyPhoneDialog } from './verify-phone-dialog';
import { format_phone_number } from '../../utils/phone_numbers';

const defaultFormData: LoginFormData = {
  password: '',
  phone: '',
  userType: '',
  organisation: '',
};

function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [formData, setFormData] = useState<LoginFormData>(defaultFormData);
  const [message, setMessage] = useState<string>('');
  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    let account_id = format_phone_number(formData.phone);
    if (account_id === '') {
      setMessage('invalid phone number');
      setIsLoading(false);
      return;
    }

    if (formData.userType === UserType.EMPLOYEE) {
      account_id = `employee/${formData.organisation.toLowerCase()}/${account_id}`;
    }

    if (formData.userType === UserType.MERCHANT) {
      account_id = `merchant/${formData.organisation.toLowerCase()}/${account_id}`;
    }

    if (formData.userType === UserType.DRIVER) {
      account_id = `driver/${formData.organisation.toLowerCase()}/${account_id}`;
    }

    const loginData = {
      account_id: account_id,
      password: formData.password,
      organisation: formData.organisation,
      account_type: formData.userType,
    };

    try {
      console.log('querying ', `${API_ENDPOINT}/api/auth/token/`);
      const authResponse: any = await axios.post(
        `${API_ENDPOINT}/api/auth/token/`,
        loginData
      );
      setResponse(authResponse);

      const data = authResponse.data;
      console.log('data = ', data);

      const authenticationData: User = {
        id: data.user.id,
        name: data.user.name,
        phone: data.user.phone_number,
        type: data.user.type,
        authToken: data.access,
        authTokenRefresh: data.refresh,
        is_admin: data.user.is_admin,
      };

      if (authenticationData.type === UserType.ORGANISATION) {
        authenticationData.organisation = {
          pk: data.user.id,
          org_id: data.user.organisation_id,
        };
      }

      if (authenticationData.type === UserType.EMPLOYEE) {
        authenticationData.branch = data.branch;

        authenticationData.organisation = {
          pk: data.user.organisation.id,
          org_id: data.user.organisation.organisation_id,
        };

        authenticationData.permissions = data.user.permissions;
      }
      if (
        authenticationData.type === UserType.MERCHANT ||
        authenticationData.type === UserType.DRIVER
      ) {
        authenticationData.organisation = {
          pk: data.user.organisation.id,
          org_id: data.user.organisation.organisation_id,
        };

        authenticationData.default_delivery_lbp =
          data.user.default_delivery_fee_lbp;
        authenticationData.default_delivery_usd =
          data.user.default_delivery_fee_usd;
      }
      setUser(authenticationData);
    } catch (e: any) {
      setIsLoading(false);
      if (axios.isAxiosError(e)) {
        const authResponse = e.response;
        if (authResponse?.status === 404) {
          setMessage('No account is associated with this phone number');
        } else if (authResponse?.data.message === 'unverified account') {
          setMessage('Your account is not verified yet.');
          setVerificationDialogOpen(true);
        } else if (authResponse?.data.message == 'unactivated account') {
          setMessage(
            'Your account is not activated yet. Contact your delivery organisation admin and ask them to activate your account and set a password for you.'
          );
        } else if (authResponse?.data.message === 'account suspended') {
          setMessage(
            'Your account has been suspended because you entered a wrong verification code too many times. Please contact us to unblock your account.'
          );
        } else if (authResponse?.statusText === 'Unauthorized') {
          setMessage('Password or phone number is incorrect, please try again');
        }
      } else {
        setMessage('Failed to authenticate. Please contact support.');
      }
    }
  };

  return (
    <>
      {message !== '' && (
        <Alert
          severity={
            response && response.status >= 200 && response.status < 300
              ? 'success'
              : 'error'
          }
        >
          {message}
        </Alert>
      )}

      <br></br>
      <form onSubmit={handleSubmit}>
        {/* User Type */}
        <FormControl key={'user-type-input'} fullWidth>
          <InputLabel id="user-type-select-label"> Profile</InputLabel>
          <Select
            labelId="user-type-select-label"
            id="entity-select"
            value={formData.userType}
            label="Entity"
            required
            disabled={isLoading}
            onChange={(e) =>
              setFormData({ ...formData, userType: e.target.value })
            }
          >
            {Object.values(UserType).map((key, index) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <br />
        <br />

        {/* Organisation */}
        {['employee', 'merchant', 'driver'].includes(formData.userType) && (
          <>
            <FormControl key={'organisation'} fullWidth>
              <TextField
                id="outlined-organisation-input"
                label="Delivery Organisation Id"
                type="text"
                value={formData.organisation}
                disabled={isLoading}
                required
                onChange={(e) =>
                  setFormData({ ...formData, organisation: e.target.value })
                }
              />
            </FormControl>
            <br />
            <br />
          </>
        )}

        {/* Phone Number */}
        <FormControl key={'phone-input'} fullWidth>
          <MuiPhoneNumber
            label="Phone Number"
            type="tel"
            disabled={isLoading}
            defaultCountry={'lb'}
            variant="outlined"
            required
            value={formData.phone}
            onChange={(newPhone: any) => {
              if (typeof newPhone === 'string') {
                setFormData({ ...formData, phone: newPhone });
              } else setFormData({ ...formData, phone: newPhone.target.value });
            }}
          />
        </FormControl>

        <br />
        <br />

        {/* Password */}
        <FormControl key={'password-input'} fullWidth>
          <TextField
            id="outlined-password-input"
            label="Password"
            type="password"
            value={formData.password}
            disabled={isLoading}
            required
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            autoComplete="current-password"
          />
        </FormControl>

        <br />
        <br />

        <Button
          style={{ display: 'block' }}
          fullWidth
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Login
        </Button>

        <hr />
        {isLoading && <LinearProgress />}
        {
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 12,
            }}
          >
            <Button
              style={{ display: 'block', width: '49%' }}
              fullWidth
              variant="outlined"
              disabled={isLoading}
              onClick={() => navigate('/signup')}
            >
              Create New Account
            </Button>

            <Button
              style={{ display: 'block', width: '49%' }}
              fullWidth
              variant="outlined"
              disabled={isLoading}
              onClick={() => navigate('/reset-password')}
            >
              Forgot Password
            </Button>
          </div>
        }
      </form>

      <VerifyPhoneDialog
        onSuccess={() => {
          setVerificationDialogOpen(false);
        }}
        isOpen={verificationDialogOpen}
        closeVerificationDialog={() => {
          setVerificationDialogOpen(false);
        }}
        phoneNumber={formData.phone}
      />
    </>
  );
}

export default LoginForm;

import React, { useContext } from 'react';
import { PageFooter } from '../../../common/pdf-builders-utils/page-footer';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Br, titleStyles } from '../../../common/pdf-builders-utils/styles';
import { Aggregation, OrdersSummary, ReportSummary } from './profit-report';
import { OrgThemeContext } from '../../../../contexts/theme-context';

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    borderBottom: '1px solid #E0E0E0',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 1000,
    marginBottom: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  column: {
    flex: 1,
  },
  text: {
    fontSize: 12,
  },
});

const sumMoney = (
  ordersSummaries: OrdersSummary[],
  extractor: (agg: Aggregation) => number
): number => {
  let result = 0;
  ordersSummaries.forEach((summary) => {
    result +=
      extractor(summary.uninvoiced) +
      extractor(summary.invoiced_for_driver) +
      extractor(summary.invoiced_for_merchant);
  });
  return result;
};

interface GlobalSummaryProps {
  reportSummary: ReportSummary;
  color: string;
}

function GlobalSummary({ reportSummary, color }: GlobalSummaryProps) {
  const totalPrice = `${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.price_usd)} $ + ${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.price_lbp)} L.L`;
  const totalDelivery = `${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.delivery_usd)} $ + ${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.delivery_lbp)} L.L`;
  const totalCommission = `${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.commission_usd)} $ + ${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.commission_lbp)} L.L`;
  let totalProfit = `${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.delivery_usd) - sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.commission_usd)} $`;
  totalProfit += `+ ${sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.delivery_lbp) - sumMoney([reportSummary.delivered_orders, reportSummary.cancelled_orders], (item) => item.commission_lbp)} L.L`;

  const deliveredOrdersPrice = `${sumMoney([reportSummary.delivered_orders], (item) => item.price_usd)} $ + ${sumMoney([reportSummary.delivered_orders], (item) => item.price_lbp)} L.L`;
  const deliveredOrdersDelivery = `${sumMoney([reportSummary.delivered_orders], (item) => item.delivery_usd)} $ + ${sumMoney([reportSummary.delivered_orders], (item) => item.delivery_lbp)} L.L`;
  const deliveredOrdersCommission = `${sumMoney([reportSummary.delivered_orders], (item) => item.commission_usd)} $ + ${sumMoney([reportSummary.delivered_orders], (item) => item.commission_lbp)} L.L`;
  let deliveredOrdersProfit = `${sumMoney([reportSummary.delivered_orders], (item) => item.delivery_usd) - sumMoney([reportSummary.delivered_orders], (item) => item.commission_usd)} $`;
  deliveredOrdersProfit += `+ ${sumMoney([reportSummary.delivered_orders], (item) => item.delivery_lbp) - sumMoney([reportSummary.delivered_orders], (item) => item.commission_lbp)} L.L`;

  const cancelledOrdersPrice = `${sumMoney([reportSummary.cancelled_orders], (item) => item.price_usd)} $ + ${sumMoney([reportSummary.cancelled_orders], (item) => item.price_lbp)} L.L`;
  const cancelledOrdersDelivery = `${sumMoney([reportSummary.cancelled_orders], (item) => item.delivery_usd)} $ + ${sumMoney([reportSummary.cancelled_orders], (item) => item.delivery_lbp)} L.L`;
  const cancelledOrdersCommission = `${sumMoney([reportSummary.cancelled_orders], (item) => item.commission_usd)} $ + ${sumMoney([reportSummary.cancelled_orders], (item) => item.commission_lbp)} L.L`;
  let cancelledOrdersProfit = `${sumMoney([reportSummary.cancelled_orders], (item) => item.delivery_usd) - sumMoney([reportSummary.cancelled_orders], (item) => item.commission_usd)} $`;
  cancelledOrdersProfit += `+ ${sumMoney([reportSummary.cancelled_orders], (item) => item.delivery_lbp) - sumMoney([reportSummary.cancelled_orders], (item) => item.commission_lbp)} L.L`;

  return (
    <View wrap={false}>
      <PageFooter color={color} />

      <View style={{ ...titleStyles.titleContainer, marginTop: 10 }}>
        <Text style={titleStyles.sectionTitle}>General Information</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>All Orders</Text>
        <View style={styles.row}>
          <Text style={styles.column}>Number of orders:</Text>
          <Text style={styles.column}>
            {reportSummary.delivered_orders.count +
              reportSummary.cancelled_orders.count}{' '}
            orders
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total price:</Text>
          <Text style={styles.column}>{totalPrice}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total delivery fee:</Text>
          <Text style={styles.column}>{totalDelivery}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total driver commission:</Text>
          <Text style={styles.column}>{totalCommission}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Net Profit:</Text>
          <Text style={styles.column}>{totalProfit}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Delivered Orders</Text>
        <View style={styles.row}>
          <Text style={styles.column}>Number of delivered orders:</Text>
          <Text style={styles.column}>
            {reportSummary.delivered_orders.count} orders
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total price:</Text>
          <Text style={styles.column}>{deliveredOrdersPrice}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total delivery fee:</Text>
          <Text style={styles.column}>{deliveredOrdersDelivery}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total driver commission:</Text>
          <Text style={styles.column}>{deliveredOrdersCommission}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Net Profit:</Text>
          <Text style={styles.column}>{deliveredOrdersProfit}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Cancelled Orders</Text>
        <View style={styles.row}>
          <Text style={styles.column}>Number of cancelled orders:</Text>
          <Text style={styles.column}>
            {reportSummary.cancelled_orders.count} orders
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total price:</Text>
          <Text style={styles.column}>{cancelledOrdersPrice}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total delivery fee:</Text>
          <Text style={styles.column}>{cancelledOrdersDelivery}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total driver commission:</Text>
          <Text style={styles.column}>{cancelledOrdersCommission}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Net Profit:</Text>
          <Text style={styles.column}>{cancelledOrdersProfit}</Text>
        </View>
      </View>
    </View>
  );
}

export default GlobalSummary;

import {
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  LinearProgress,
  Switch,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useContext, useState } from 'react';
import {
  CreateOrEditMerchantProps,
  MerchantType,
} from '../../../../interfaces/delivery-company/merchants';
import MuiPhoneNumber from 'mui-phone-number';
import ConfirmAction from '../../../common/confirm-action/confirm-action';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { emptyMerchant } from '../../../../data/merchants';
import { UserType } from '../../../../enums/users';
import AddressInputWithAutocomplete from '../../../common/adress-input-with-autocomplete/address-input-with-autocomplete';
import { NotificationMode } from '../../../../enums/notifications';
import { display_notification_mode } from '../../../../utils/notification_mode_icons';
import { ErrorsFlattenner } from '../../../common/errors-flattenner/errors-flattenner';
import { parseFloatWithPrecision } from '../../../../utils/decimal';

function CreateMerchantForm({
  defaultMerchant,
  successCallback,
  headless,
}: CreateOrEditMerchantProps) {
  console.log(defaultMerchant);
  const [merchantData, setMerchantData] = useState<MerchantType | undefined>(
    defaultMerchant || emptyMerchant
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [confirmDeleteMerchant, setConfirmDeleteMerchant] =
    useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const [enableModifyPassword, setEnableModifyPassword] =
    useState<boolean>(false);
  const { user } = useContext(AuthContext);

  console.log('check: ', user);
  const canChangeMerchant =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('change_merchant');
  const canCreateMerchant =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('add_merchant');
  const canDeleteMerchant =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('delete_merchant');

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  console.log(merchantData);

  console.log('checkpoiny');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (merchantData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Merchant...');
      const requestData = {
        name: merchantData.name,
        phone_number: merchantData.phone_number,
        address: merchantData.address,
        organisation: user?.organisation,
        password: merchantData.password,
        notification_mode: merchantData.notification_mode,
        default_delivery_fee_lbp: merchantData.default_delivery_fee_lbp,
        default_delivery_fee_usd: merchantData.default_delivery_fee_usd,
      };

      console.log();

      try {
        let response;
        const endpoint = `${API_ENDPOINT}/api/v1/merchant/${merchantData.id}/`;

        if (merchantData.id === '') {
          console.log('request data:', requestData);
          // create new
          response = await axios.post(
            `${API_ENDPOINT}/api/v1/merchants/`,
            requestData,
            config
          );
        } else {
          // edit existing
          response = await axios.put(endpoint, requestData, config);
        }
        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Merchant saved successfully');
          successCallback && successCallback(response.data);
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to save merchant: ' + e.message);
        } else {
          setMessage('Failed to save merchant');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteMerchant = async () => {
    if (merchantData && merchantData.id !== '') {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/v1/merchant/${merchantData.id}/`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setMerchantData(undefined);
          setMessage('Merchant deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to delete merchant: ' + e.message);
        } else {
          setMessage('Failed to delete merchant');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      {!headless && (
        <Typography variant="h3" component="h3" sx={{ textAlign: 'center' }}>
          {defaultMerchant === undefined ? 'Create Merchant' : 'Edit Merchant'}
        </Typography>
      )}

      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message !== '' && (
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              {message}

              {response && response.status >= 300 && (
                <ErrorsFlattenner rawErrors={response.data} />
              )}
            </Alert>
          )}

          {merchantData && (
            <>
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Merchant Name"
                    type="text"
                    variant="outlined"
                    required
                    value={merchantData.name}
                    onChange={(e) =>
                      setMerchantData({ ...merchantData, name: e.target.value })
                    }
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <MuiPhoneNumber
                    label="Merchant Phone Number"
                    type="tel"
                    defaultCountry={'lb'}
                    variant="outlined"
                    required
                    value={merchantData.phone_number}
                    onChange={(newPhone) => {
                      if (typeof newPhone === 'string') {
                        setMerchantData({
                          ...merchantData,
                          phone_number: newPhone,
                        });
                      } else
                        setMerchantData({
                          ...merchantData,
                          phone_number: newPhone.target.value,
                        });
                    }}
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <AddressInputWithAutocomplete
                    initialAddress={merchantData.address}
                    callback={(address, district, governorate) => {
                      setMerchantData((prev) => {
                        return {
                          ...(prev ?? emptyMerchant),
                          address: address,
                        };
                      });
                    }}
                    onBlur={(val) =>
                      setMerchantData((prev) => {
                        return {
                          ...(prev ?? emptyMerchant),
                          address: val,
                        };
                      })
                    }
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <Grid container spacing={1}>
                    <Grid xs={6}>
                      <TextField
                        label="Default Delivery $"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={merchantData.default_delivery_fee_usd}
                        inputProps={{
                          maxLength: 13,
                          step: '0.01',
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            'wheel',
                            (e) => e.preventDefault(),
                            { passive: false }
                          )
                        }
                        onChange={(e) =>
                          setMerchantData({
                            ...merchantData,
                            default_delivery_fee_usd: parseFloatWithPrecision(
                              e.target.value
                            ),
                          })
                        }
                      />
                    </Grid>

                    <Grid xs={6}>
                      <TextField
                        label="Default Delivery L.L"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        onFocus={(e) =>
                          e.target.addEventListener(
                            'wheel',
                            (e) => e.preventDefault(),
                            { passive: false }
                          )
                        }
                        value={merchantData.default_delivery_fee_lbp}
                        onChange={(e) =>
                          setMerchantData({
                            ...merchantData,
                            default_delivery_fee_lbp: parseFloatWithPrecision(
                              e.target.value
                            ),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <br /> <br />
                <FormControl fullWidth>
                  <InputLabel id="test-select-label">
                    Notification Mode
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    id="demo-simple-select"
                    value={Object.values(NotificationMode).indexOf(
                      merchantData.notification_mode
                    )}
                    label="Notification Mode"
                    onChange={(e) => {
                      const index = parseInt(e.target.value.toString());
                      const state = Object.values(NotificationMode)[index];

                      const newMerchantData = {
                        ...merchantData,
                        notification_mode: state,
                      };

                      setMerchantData(newMerchantData);
                    }}
                  >
                    {Object.values(NotificationMode).map((mode, index) => (
                      <MenuItem key={index} value={index}>
                        {display_notification_mode(mode)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <br />
                <div
                  style={{
                    display: 'flex',
                    gap: 3,
                    alignItems: 'center',
                  }}
                >
                  {
                    <>
                      <Switch
                        onChange={() =>
                          setEnableModifyPassword(!enableModifyPassword)
                        }
                        checked={enableModifyPassword}
                      />
                      {!enableModifyPassword && <>Set merchant password</>}
                    </>
                  }

                  {enableModifyPassword && (
                    <FormControl fullWidth>
                      <TextField
                        label="Merchant Password"
                        type="password"
                        variant="outlined"
                        required
                        value={merchantData.password}
                        onChange={(e) =>
                          setMerchantData({
                            ...merchantData,
                            password: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  )}
                </div>
                <br />
                <div style={{ display: 'flex', gap: 7 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      (merchantData.id !== '' && !canChangeMerchant) ||
                      (merchantData.id === '' && !canCreateMerchant)
                    }
                  >
                    Save Merchant
                  </Button>

                  {merchantData.id !== '' && (
                    <Button
                      onClick={() => {
                        setConfirmDeleteMerchant(true);
                      }}
                      disabled={!canDeleteMerchant}
                      variant="outlined"
                      color="error"
                    >
                      Delete Merchant
                    </Button>
                  )}
                </div>
              </form>
            </>
          )}
        </>
      )}

      <ConfirmAction
        message={'Are you sure you want to delete this merchant?'}
        action={deleteMerchant}
        isOpen={confirmDeleteMerchant}
        handleClose={() => setConfirmDeleteMerchant(false)}
        isSevereAction={true}
        confirmSevereActionTarget="delete merchant"
      >
        <div>
          Attention: This action will delete all orders registered for this
          merchant!
        </div>
      </ConfirmAction>
    </>
  );
}

export default CreateMerchantForm;

import { Box, Typography } from '@mui/material';
import DriverOrdersListing from './driver-orders-listing/driver-orders-listing';

function DriverOrders() {
  return (
    <div>
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          My Orders
        </Typography>
        <br />
        <DriverOrdersListing />
      </Box>
    </div>
  );
}

export default DriverOrders;

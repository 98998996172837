import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Howl } from 'howler';
import { Html5QrcodeResult } from 'html5-qrcode';
import React, { useContext } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useAttachedOrgs } from '../../../hooks/use-attached-orgs';
import OrgSetter from '../../common/org-setter/org-setter';
import BarcodeScanner from '../../common/scanner/scanner-input';
import OrderLookupDialog from './driver-order-lookup-dialog';
import { AuthContext } from '../../../contexts/user-context/user-context';

function DriverOrderLookup() {
  const [useCameraChecked, setUseCameraChecked] =
    React.useState<boolean>(false);
  const [orderId, setOrderId] = React.useState<string>('');

  const [orderIdInput, setOrderIdInput] = React.useState<string>('');
  const [orderScannedId, setOrderScannedId] = React.useState<string>('');
  const [searchByOrderID, setSearchByOrderID] = React.useState<boolean>(true);
  const { user } = useContext(AuthContext);

  const beepSound = new Howl({
    src: ['/media/Barcode-scanner-beep-sound.mp3'],
    volume: 0.0075,
  });

  const onNewCameraScanResult = (
    newDecodedText: string,
    newDecodedResult: Html5QrcodeResult
  ) => {
    setOrderScannedId(newDecodedText);
  };

  const handleUseCameraChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseCameraChecked(event.target.checked);
  };

  const onCloseDialog = () => {
    setOrderId('');
    setOrderIdInput('');
    setOrderScannedId('');
  };

  React.useEffect(() => {
    if (orderId === '') {
      if (orderScannedId !== '') beepSound.play();
      setOrderId(orderScannedId);
    }
  }, [orderScannedId]);

  return (
    <div>
      <Container>
        <Box>
          <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
          >
            Order Lookup
          </Typography>

          <br />

          <Grid container>
            <Grid sm={12} md={6}>
              <FormControl>
                <Select
                  value={searchByOrderID ? 'id' : 'reference'}
                  onChange={(e) => setSearchByOrderID(e.target.value === 'id')}
                >
                  <MenuItem key={'id'} value={'id'}>
                    Search by order id
                  </MenuItem>
                  <MenuItem key={'reference'} value={'reference'}>
                    Search by ref. number
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Use Device Camera to scan barcodes */}
            <Grid sm={12} md={6}>
              <div style={{ display: 'flex' }}>
                <Switch
                  onChange={handleUseCameraChange}
                  checked={useCameraChecked}
                />
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  Use device camera to scan orders
                </div>
              </div>
            </Grid>
          </Grid>
          {!useCameraChecked && (
            <>
              <br />
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setOrderId(orderIdInput);
                }}
              >
                <div style={{ display: 'flex', gap: 3, width: '100%' }}>
                  <br />
                  <FormControl fullWidth>
                    <TextField
                      label="Type order id or scan order barcode"
                      type="text"
                      variant="outlined"
                      required
                      value={orderIdInput}
                      onChange={(e) => setOrderIdInput(e.target.value)}
                    />
                  </FormControl>
                  <Button variant="contained" type={'submit'}>
                    <SearchIcon />
                  </Button>
                </div>
              </form>
            </>
          )}

          {
            /* Open device camera if required */
            <>
              <br />
              <div
                style={{
                  width: '100%',
                }}
              >
                {useCameraChecked && (
                  <BarcodeScanner onSuccessfullScan={onNewCameraScanResult} />
                )}
              </div>
            </>
          }

          {orderId.length > 0 && (
            <OrderLookupDialog
              orderId={orderId}
              onCloseDialog={onCloseDialog}
              orgId={user?.organisation?.org_id}
              searchByOrderId={searchByOrderID}
            />
          )}
        </Box>
      </Container>
    </div>
  );
}

export default DriverOrderLookup;

import BarChartIcon from '@mui/icons-material/BarChart';
import { Alert, Card, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { BarChart } from '@mui/x-charts';
import { ResponsiveContainer } from 'recharts';
import { PALETTE } from '../../../../configurations/global.config';
import {
  cardHeaderStyle,
  cardStyle,
} from '../../../common/styles/grid-card.style';
import { WidgetProps } from '../home';
import { useContext } from 'react';
import { OrgThemeContext } from '../../../../contexts/theme-context';

function DeliveredOrdersHistoryWidget({
  stats,
  isLoading,
  errorMessage,
}: WidgetProps) {
  const { color } = useContext(OrgThemeContext);
  return (
    <Grid style={{ textAlign: 'center' }} xs={12} md={6}>
      <Card style={cardStyle}>
        <div style={cardHeaderStyle(color)}>
          <BarChartIcon />
          <div>
            <b>Delivered Orders History</b>
          </div>
        </div>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {errorMessage !== '' && (
              <Alert severity="error">{errorMessage}</Alert>
            )}

            {stats !== undefined && (
              <ResponsiveContainer height={350}>
                <BarChart
                  xAxis={[
                    {
                      scaleType: 'band',
                      data: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ],
                    },
                  ]}
                  series={[
                    {
                      data: stats.order_results_per_month.delivered,
                      color: PALETTE[2],
                      label: 'Delivered',
                    },
                    {
                      data: stats.order_results_per_month.cancelled,
                      color: PALETTE[0],
                      label: 'Cancelled',
                    },
                  ]}
                />
              </ResponsiveContainer>
            )}
          </>
        )}
      </Card>
    </Grid>
  );
}

export default DeliveredOrdersHistoryWidget;

import Decimal from 'decimal.js';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { parseFloatWithPrecision } from '../../../utils/decimal';

export const compute_total_price_usd = (orders: OrderType[]): Decimal => {
  return orders
    .map((item) => parseFloatWithPrecision(item.price_usd.toString()))
    .reduce((accumulator, currentValue) => accumulator.add(currentValue), Decimal(0));
};

export const compute_total_price_lbp = (orders: OrderType[]): Decimal => {
  return orders
    .map((item) => parseFloatWithPrecision(item.price_lbp.toString()))
    .reduce((accumulator, currentValue) => accumulator.add(currentValue), Decimal(0));
};

export const compute_total_delivery_fee_usd = (orders: OrderType[]): Decimal => {
  return orders
    .map((item) => parseFloatWithPrecision(item.delivery_fee_usd.toString()))
    .reduce((accumulator, currentValue) => accumulator.add(currentValue), Decimal(0));
};

export const compute_total_delivery_fee_lbp = (orders: OrderType[]): Decimal => {
  return orders
    .map((item) => parseFloatWithPrecision(item.delivery_fee_lbp.toString()))
    .reduce((accumulator, currentValue) => accumulator.add(currentValue), Decimal(0));
};

export const compute_total_commission_usd = (orders: OrderType[]): Decimal => {
  return orders
    .map((item) => parseFloatWithPrecision(item.driver_commission_usd.toString()))
    .reduce((accumulator, currentValue) => accumulator.add(currentValue), Decimal(0));
};

export const compute_total_commission_lbp = (orders: OrderType[]): Decimal => {
  return orders
    .map((item) => parseFloatWithPrecision(item.driver_commission_lbp.toString()))
    .reduce((accumulator, currentValue) => accumulator.add(currentValue), Decimal(0));
};

import BackspaceIcon from '@mui/icons-material/Backspace';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useContext, useState } from 'react';
import { DoubleDate } from './double-date-picker.interfaces';
import { OrgThemeContext } from '../../../contexts/theme-context';

interface DoubleDatePickerProps {
  dateFilterAction: any;
}
function DoubleDatePicker({ dateFilterAction }: DoubleDatePickerProps) {
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });

  const { color } = useContext(OrgThemeContext);

  return (
    <Box
      component="fieldset"
      border={'none'}
      borderTop={'1px solid rgb(0,0,0,0.1)'}
    >
      <legend>
        <b>Date Controls</b>
      </legend>

      <form>
        <Grid container spacing={1} width={'100%x'}>
          <Grid xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select">Operation</InputLabel>
              <Select
                required
                fullWidth
                id="demo-simple-select"
                value={dateFilter.operation ?? 'created'}
                label={'Operation'}
                onChange={(e) => {
                  const newOperation = e.target.value.toString();
                  setDateFilter({
                    ...dateFilter,
                    operation: newOperation,
                  });
                }}
              >
                <MenuItem key={0} value={'created'}>
                  Creation Date
                </MenuItem>

                <MenuItem key={1} value={'assigned'}>
                  Driver Assignment
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={dateFilter.startDate}
                  onChange={(newValue) =>
                    setDateFilter({
                      ...dateFilter,
                      startDate: newValue,
                    })
                  }
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid xs={12} md={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  value={dateFilter.endDate}
                  onChange={(newValue) =>
                    setDateFilter({
                      ...dateFilter,
                      endDate: newValue,
                    })
                  }
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid xs={12} md={3}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <IconButton
                type="submit"
                aria-label="search"
                onClick={(e) => {
                  e.preventDefault();
                  dateFilterAction(dateFilter);
                }}
              >
                <SearchIcon style={{ fill: `${color}` }} />
              </IconButton>

              <IconButton
                type="submit"
                aria-label="search"
                onClick={(e) => {
                  e.preventDefault();
                  setDateFilter({ startDate: null, endDate: null });
                  dateFilterAction({ startDate: null, endDate: null });
                }}
              >
                <BackspaceIcon style={{ fill: `${color}` }} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default DoubleDatePicker;

import React, { useContext, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { User } from '../../../interfaces/common/authentication';
import DeleteOrdersAction from './delete-orders-action';
import ExtractAsPDFAction from './extract-as-pdf';
import { BulkActionProps } from './props.interface';
import SetBranchAction from './set-branch-action';
import SetDeliveryStateAction from './set-delivery-state-action';
import SetDriverAction from './set-driver-action';
import { UserType } from '../../../enums/users';
import PrintWaybills from './print-waybills';

interface Actions {
  [key: string]: (props: any) => JSX.Element; //string index signature
}

const getCompanyActions = (user: User): Actions => {
  const CompanyActions: Actions = {};
  if (
    user.type === UserType.ORGANISATION ||
    user.permissions?.includes('change_order')
  ) {
    CompanyActions['Set Delivery State'] = (props: any) => (
      <SetDeliveryStateAction {...props} />
    );
    CompanyActions['Set Branch'] = (props: any) => (
      <SetBranchAction {...props} />
    );
    CompanyActions['Assign Driver'] = (props: any) => (
      <SetDriverAction {...props} />
    );
  }

  if (
    user.type === UserType.ORGANISATION ||
    user.permissions?.includes('delete_order')
  ) {
    CompanyActions['Delete Orders'] = (props: any) => (
      <DeleteOrdersAction {...props} />
    );
  }

  CompanyActions['Extract As PDF'] = (props: any) => (
    <ExtractAsPDFAction {...props} />
  );
  CompanyActions['Print Waybills'] = (props: any) => (
    <PrintWaybills {...props} />
  );

  return CompanyActions;
};

const MerchantActions = {
  'Delete Orders': (props: any) => <DeleteOrdersAction {...props} />,
  'Extract As PDF': (props: any) => <ExtractAsPDFAction {...props} />,
  'Print Waybills': (props: any) => <PrintWaybills {...props} />,
};

const getDefaultAction = (user: User | null): string => {
  if (!user) return '';
  if (user.type === UserType.MERCHANT) return 'Delete Orders';
  return 'Set Delivery State';
};

const getActions = (user: User | null): Actions => {
  if (!user) return {};
  if (user.type === UserType.MERCHANT) {
    return MerchantActions;
  }

  return getCompanyActions(user);
};
function BulkAction({
  selectedOrders,
  bulkEndpoint,
  refetchOrders,
  extractAsPdf,
}: BulkActionProps) {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState<string>();

  const actions = getActions(user);
  const orgId = user?.organisation?.org_id ?? 'UNKNOWN';

  const resetState = () => {
    setAction('');
  };

  useEffect(() => {
    resetState();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    resetState();
  };

  const handleClose = () => {
    setOpen(false);
    resetState();
    refetchOrders();
  };

  return (
    <div>
      <Button
        variant="contained"
        disabled={selectedOrders.size === 0}
        onClick={handleClickOpen}
      >
        Actions
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogContent>
          <br />
          <FormControl fullWidth>
            <InputLabel id="search-select-label">Action</InputLabel>

            <Select
              value={action}
              label="Search By"
              id="search-select-label"
              onChange={(e) => setAction(e.target.value)}
            >
              {Object.keys(actions).map((action) => (
                <MenuItem key={action} value={action}>
                  {action}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <br />
          <br />

          {action &&
            actions[action]({
              selectedOrders,
              handleClose,
              bulkEndpoint,
              extractAsPdf,
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BulkAction;

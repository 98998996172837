import FeedIcon from '@mui/icons-material/Feed';
import { Card } from '@mui/material';
import { DriverType } from '../../../interfaces/delivery-company/drivers';
import {
  cardHeaderStyle,
  cardStyle,
  cardTableStyle,
} from '../../common/styles/grid-card.style';
import { useContext } from 'react';
import { OrgThemeContext } from '../../../contexts/theme-context';

interface DriverIntroCardProps {
  driver: DriverType;
}

function DriverIntroCard({ driver }: DriverIntroCardProps) {
  const { color } = useContext(OrgThemeContext);
  return (
    <Card style={cardStyle}>
      <div style={cardHeaderStyle(color)}>
        <FeedIcon />
        <div>
          <b>Driver Information</b>
        </div>
      </div>

      <table style={cardTableStyle}>
        <tbody>
          <tr>
            <th>Name</th>
            <td>{driver.name}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <td>{driver.phone_number}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}

export default DriverIntroCard;

import React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";

// Types for the flattened error object
interface ValidationError {
    [key: string]: string[];
}


const flattenErrors = (errors: any): ValidationError => {
    const result: ValidationError = {};

    const flatten = (obj: any, path: string[] = []) => {
        if (Array.isArray(obj)) {
            // If the item is an array, we need to iterate over each item, but avoid including the index in the path
            obj.forEach((item) => flatten(item, path));
        } else if (typeof obj === "object" && obj !== null) {
            // If the item is an object, recurse through its properties
            Object.entries(obj).forEach(([key, value]) => {
                flatten(value, [...path, key]);
            });
        } else {
            // Base case: we've reached a primitive value (string)
            result[path.join(".")] = Array.isArray(obj) ? obj : [obj];
        }
    };

    flatten(errors);
    return result;
};


interface ErrorAlertProps {
    rawErrors: any;
}

export const ErrorsFlattenner: React.FC<ErrorAlertProps> = ({ rawErrors }) => {

    const flattennedErrors = flattenErrors(rawErrors)

    if (Object.keys(flattennedErrors).length === 0) {
        return null; // If no errors, don't render anything
    }

    // Flatten and format errors into a list of messages
    const errorMessages = Object.entries(flattennedErrors).map(([field, messages]) => {
        return (
            <Box key={field}>
                <strong>{field.replace(/\./g, " ")}:</strong>
                <ul>
                    {messages.map((msg, idx) => (
                        <li key={idx}>{msg}</li>
                    ))}
                </ul>
            </Box>
        );
    });

    return (
        <Box> <hr /> {errorMessages}</Box>
    );
};

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthContext } from './user-context';
import axios from 'axios';
import { UserType } from '../../enums/users';
import { API_ENDPOINT } from '../../configurations/global.config';

type Props = {
  children?: ReactNode;
};
export interface Permission {
  id: string;
  name: string;
  representation: string;
}

type IPermissionsContext = {
  permissions: Permission[];
};

const initialValue: IPermissionsContext = {
  permissions: [],
};

const PermissionsContext = createContext<IPermissionsContext>(initialValue);

const PermissionsContextProvider = ({ children }: Props) => {
  const [permissions, setPermission] = useState<Permission[]>([]);
  const { user, setUser } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };
  useEffect(() => {
    const getAndSetPermissions = async () => {
      if (user === null) return;
      if (user.type === UserType.MERCHANT || user.type === UserType.DRIVER)
        return;

      try {
        const response = await axios.get<Permission[]>(
          `${API_ENDPOINT}/api/permissions/`,
          config
        );
        setPermission(response.data);
      } catch (e) {
        setUser(null);
        return;
      }
    };

    getAndSetPermissions();
  }, [user]);

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsContext, PermissionsContextProvider };

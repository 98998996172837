import { Alert, Box, LinearProgress, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { OrderStats } from '../home/home';
import LogoUploader from './logo-uploader/logo-uploader';
import Section from './section';
import NotificationManager from './notification-manager/notification-manager';
import axios from 'axios';
import { OrganisationSettings } from '../../../interfaces/delivery-company/orders';
import { API_ENDPOINT } from '../../../configurations/global.config';
import ThemeColor from './color-theme/color-theme';
import Grid2 from '@mui/material/Unstable_Grid2';

export interface VersionedLogo {
  logoUrl: string;
  version: number;
}

function Settings() {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>();
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const [orgLogoUrl, setOrgLogoUrl] = useState<VersionedLogo>({
    logoUrl: `https://parcel-tracer-public.s3.eu-central-1.amazonaws.com/${user?.organisation?.org_id}/logo.png`,
    version: 0,
  });
  const [orgSettings, setOrgSettings] = useState<OrganisationSettings>({});

  useEffect(() => {
    const fetchOrgSettings = async () => {
      try {
        const response = await axios.get<OrganisationSettings>(
          `${API_ENDPOINT}/api/org/settings`,
          config
        );
        setOrgSettings(response.data);

        if (!response.data.has_logo)
          updateLogoUrl(
            `https://parcel-tracer-public.s3.eu-central-1.amazonaws.com/system/logo.png`
          );
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setError('Failed to load organisation settings: ' + e.message);
        } else {
          setError('Failed to load  organisation settings');
        }
        console.log(e);
      }

      setLoading(false);
    };

    fetchOrgSettings();
  }, []);

  console.log(orgLogoUrl);

  const updateLogoUrl = (newUrl: string) => {
    setOrgLogoUrl({
      logoUrl: newUrl,
      version: orgLogoUrl.version + 1,
    });
  };

  const getFreshLogoUrl = () => {
    return `${orgLogoUrl.logoUrl}?${orgLogoUrl.version}`;
  };

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Settings
        </Typography>

        {loading ? (
          <LinearProgress />
        ) : (
          <>
            {error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <>
                <Grid2 spacing={2} container>
                  <Grid2 xs={12} md={6}>
                    <Section title="Company Logo">
                      <LogoUploader
                        logoUrl={getFreshLogoUrl()}
                        updateLogoUrl={updateLogoUrl}
                      />
                      <Alert severity="info">
                        {' '}
                        Logo will be visible to customers in whatsapp
                        notifications and order tracking pages{' '}
                      </Alert>
                    </Section>
                  </Grid2>

                  <Grid2 xs={12} md={6}>
                    <Section title="Customize Theme">
                      <ThemeColor settings={orgSettings} />
                    </Section>
                  </Grid2>
                </Grid2>

                <Section title="Customer Notifications">
                  <NotificationManager
                    logoUrl={getFreshLogoUrl()}
                    settings={orgSettings}
                  />
                </Section>
              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default Settings;

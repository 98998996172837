import { Button, Dialog, DialogContent } from '@mui/material';
import { Html5QrcodeResult } from 'html5-qrcode';
import React, { useState } from 'react';
import BarcodeScanner from '../common/scanner/scanner-input';
import OrderLookupDialog from './order-lookup-dialog';
import { isMobile } from 'react-device-detect';

interface QuickScanDialogProps {
  open: boolean;
  onClose: () => void;
}

function QuickScanDialog({ open, onClose }: QuickScanDialogProps) {
  const [scannedOrderId, setScannedOrderId] = useState('');
  const [orderId, setOrderId] = useState('');

  const resetStates = () => {
    setOrderId('');
    setScannedOrderId('');
  };

  const onNewCameraScanResult = (
    newDecodedText: string,
    newDecodedResult: Html5QrcodeResult
  ) => {
    setScannedOrderId(newDecodedText);
  };

  const beepSound = new Howl({
    src: ['/media/Barcode-scanner-beep-sound.mp3'],
    volume: 0.0075,
  });

  React.useEffect(() => {
    if (orderId === '') {
      if (scannedOrderId !== '') beepSound.play();
      setOrderId(scannedOrderId);
    }
  }, [scannedOrderId]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogContent>
        <div
          style={{
            width: '100%',
          }}
        >
          {
            open && <BarcodeScanner
              onSuccessfullScan={onNewCameraScanResult}
            />
          }
        </div>
      </DialogContent>

      {orderId.length > 0 && (
        <OrderLookupDialog orderId={orderId} onCloseDialog={resetStates} />
      )}

      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button
          sx={{ width: '70%', marginBottom: 3 }}
          color="primary"
          variant="contained"
          onClick={() => {
            resetStates();
            onClose();
          }}
        >
          {' '}
          Close{' '}
        </Button>
      </div>
    </Dialog>
  );
}

export default QuickScanDialog;

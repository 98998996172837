import phone from "phone"

const trimLeadingZero = (phone: string): string => {
    if (phone.length > 0 && phone[0] == "0")
        return phone.slice(1)

    return phone
}

const lebanesePhoneRegex = /^\+961?(0?1|0?3[0-9]?|0?[4-6]|70|71|76|78|79|0?7|81|0?8|0?9)\d{6}$/

export const is_lebanese_number = (phoneNumber: string): boolean => {
    return lebanesePhoneRegex.test(phoneNumber)
}


export const preprocess_lebanese_phone = (phonenumber: string): string => {
    const countryCode = "+961"
    if (phonenumber[countryCode.length] == "0") {
        return `${countryCode}${phonenumber.slice(countryCode.length + 1)}`
    }
    return phonenumber
}

export const format_phone_number = (phoneNumber: string): string => {
    // remove white spaces
    phoneNumber = phoneNumber.replaceAll(" ", "")

    // remove leading zeroes
    phoneNumber = trimLeadingZero(phoneNumber)

    // add plus sign if missing
    if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`
    }

    // process lebanese phone numbers separately
    if (is_lebanese_number(phoneNumber))
        return preprocess_lebanese_phone(phoneNumber)

    const process = phone(phoneNumber)

    if (process.isValid) {
        return process.phoneNumber
    }

    return ""
}
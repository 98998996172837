import { View } from '@react-pdf/renderer';
import { useContext } from 'react';
import { OrgThemeContext } from '../../../contexts/theme-context';

interface PageFooterProps {
  color: string;
}

export const PageFooter = ({ color }: PageFooterProps) => {
  return (
    <View
      fixed
      style={{
        width: '100%',
        height: 10,
        backgroundColor: color,
      }}
    ></View>
  );
};

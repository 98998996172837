import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import {
  API_ENDPOINT,
  primaryPaletteColor,
} from '../../../../configurations/global.config';
import { SketchPicker } from 'react-color';
import { OrganisationSettings } from '../../../../interfaces/delivery-company/orders';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import axios from 'axios';
import { OrgThemeContext } from '../../../../contexts/theme-context';

interface ThemeColorProps {
  settings: OrganisationSettings;
}

const ThemeColor = ({ settings }: ThemeColorProps) => {
  const [inputColor, setInputColor] = useState(
    settings.custom_primary_color || primaryPaletteColor
  ); // Default color
  const { setColor } = useContext(OrgThemeContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Success message state

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleChangeComplete = (color: any) => {
    setInputColor(color.hex); // Update the color state
  };

  const handleSubmit = async () => {
    setError(null);
    setSuccessMessage(null);
    setLoading(true);

    try {
      const requestData = {
        custom_primary_color: inputColor,
      };

      await axios.post(`${API_ENDPOINT}/api/org/settings`, requestData, config);
      setSuccessMessage('Theme color updated successfully');
      setColor(inputColor);
    } catch (err) {
      setError('Failed to update theme color. Please try again.');
    }

    setLoading(false);
  };

  return (
    <Box>
      <>
        {error && <Alert severity="error">{error}</Alert>}

        {successMessage && <Alert severity="success"> {successMessage} </Alert>}

        {(error || successMessage) && <br />}
      </>
      <TextField
        label="Pick a Color"
        variant="outlined"
        value={inputColor}
        InputProps={{
          startAdornment: (
            <Box
              sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: inputColor,
                marginRight: 1,
              }}
            />
          ),
          endAdornment: (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
              <FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save
                </Button>

                {loading && <CircularProgress />}
              </FormControl>
            </div>
          ),
        }}
        fullWidth
      />
      <br />

      {/* React Color Picker */}
      <SketchPicker
        color={inputColor}
        onChangeComplete={handleChangeComplete}
      />
    </Box>
  );
};

export default ThemeColor;

import {
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  Autocomplete,
  LinearProgress,
  Dialog,
  IconButton,
  DialogContent,
  FormHelperText,
  Switch,
} from '@mui/material';
import { useContext, useState } from 'react';
import {
  CreateEmployeeFormProps,
  EmployeeType,
} from './create-employee-form.interfaces';
import { emptyEmployee } from './create-employee-form.utils';
import React from 'react';
import MuiPhoneNumber from 'mui-phone-number';
import ConfirmAction from '../../../common/confirm-action/confirm-action';
import axios, { AxiosResponse } from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import AutocompleteWithThrottle from '../../../common/autocomplete-with-throttle/autocomplete-with-throttle';
import { BranchType } from '../../../../interfaces/delivery-company/branches';
import CreateBranchForm from '../../branches/create-branch-form/create-branch-form';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Permission,
  PermissionsContext,
} from '../../../../contexts/user-context/permissions-context';
import { get_permissions_diff } from '../util';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { ErrorsFlattenner } from '../../../common/errors-flattenner/errors-flattenner';

function CreateEmployeeForm({ defaultEmployeeId }: CreateEmployeeFormProps) {
  const [employeeData, setEmployeeData] = useState<EmployeeType>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [confirmDeleteEmployee, setConfirmDeleteEmployee] =
    useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const [creatingNewBranchOpen, setCreatingNewBranchOpen] =
    useState<boolean>(false);
  const { permissions } = useContext(PermissionsContext);
  const ALL_PERMISSIONS = permissions;

  const [enableModifyPassword, setEnableModifyPassword] =
    useState<boolean>(false);

  const [permissionInput, setPermissionInput] = useState<string>('');

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  React.useEffect(() => {
    const getAndSetEmployee = async () => {
      if (defaultEmployeeId !== undefined) {
        setIsLoading(true);
        try {
          console.log(`${API_ENDPOINT}/api/employee/${defaultEmployeeId}`);
          const response = await axios.get<EmployeeType>(
            `${API_ENDPOINT}/api/org/employee/${defaultEmployeeId}`,
            config
          );
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setEmployeeData(response.data);
          }
        } catch (e) {
          setEmployeeData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load employee  data: ' + e.message);
          } else {
            setMessage('Failed to load employee  data');
          }
          console.log(e);
        }
        setIsLoading(false);
      } else {
        setEmployeeData({
          ...emptyEmployee,
          account: { user_permissions: ALL_PERMISSIONS },
        });
      }
    };
    getAndSetEmployee();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (user === null) return;
    if (employeeData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Employee...');
      const requestData = {
        name: employeeData.name,
        phone_number: employeeData.phone_number,
        branch: employeeData.branch,
        account: employeeData.account,
        organisation: user?.organisation,
        password: employeeData.password,
        is_admin: employeeData.is_admin,
      };

      console.log(requestData);

      try {
        const endpoint = `${API_ENDPOINT}/api/org/employee/${employeeData.id}/`;

        let response;

        console.log('Request Data: ');
        console.log(requestData);

        if (defaultEmployeeId === undefined) {
          // create new
          response = await axios.post(
            `${API_ENDPOINT}/api/org/employees/`,
            requestData,
            config
          );
        } else {
          // edit existing
          response = await axios.patch(endpoint, requestData, config);
        }
        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Employee saved successfully');
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to save employee: ' + e.message);
        } else {
          setMessage('Failed to save employee');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteEmployee = async () => {
    if (employeeData) {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/org/employee/${employeeData.id}/`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setEmployeeData(undefined);
          setMessage('Employee deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setMessage('Failed to delete employee: ' + e.message);
        } else {
          setMessage('Failed to delete employee');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  const handleFetchBranches = async (query: string) => {
    if (employeeData) {
      const endpoint = `${API_ENDPOINT}/api/v1/branches/?page_size=10&name__contains=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: BranchType[];
        }>(endpoint, config);

        console.log(response);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  return (
    <>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        {defaultEmployeeId === undefined ? 'Create Employee' : 'Edit Employee'}
      </Typography>
      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message != '' && (
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              <>
                {message}

                {response && response.status >= 300 && (
                  <ErrorsFlattenner rawErrors={response.data} />
                )}
              </>
            </Alert>
          )}

          {employeeData && (
            <>
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Employee Name"
                    type="text"
                    variant="outlined"
                    required
                    value={employeeData.name}
                    onChange={(e) =>
                      setEmployeeData({ ...employeeData, name: e.target.value })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <MuiPhoneNumber
                    label="Employee Phone Number"
                    type="tel"
                    defaultCountry={'lb'}
                    variant="outlined"
                    required
                    value={employeeData.phone_number}
                    onChange={(newPhone) => {
                      if (typeof newPhone === 'string') {
                        setEmployeeData({
                          ...employeeData,
                          phone_number: newPhone,
                        });
                      } else
                        setEmployeeData({
                          ...employeeData,
                          phone_number: newPhone.target.value,
                        });
                    }}
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <AutocompleteWithThrottle
                    label={'Branch'}
                    onClickAdd={() => setCreatingNewBranchOpen(true)}
                    fetch={handleFetchBranches}
                    value={employeeData.branch ? employeeData.branch : null}
                    getOptionLabel={(option: BranchType) =>
                      option.name + ' - ' + option.address
                    }
                    required={false}
                    onChange={(e, value) => {
                      const newEmployeeData = {
                        ...employeeData,
                        branch: value ? value : null,
                      };

                      setEmployeeData(newEmployeeData);
                    }}
                  />
                </FormControl>

                <br />
                <br />

                <>
                  <Switch
                    onChange={() =>
                      setEmployeeData({
                        ...employeeData,
                        is_admin: !employeeData.is_admin,
                      })
                    }
                    checked={employeeData.is_admin}
                  />
                  Grant Admin Privileges.
                </>

                <br />

                {!employeeData.is_admin && (
                  <>
                    {' '}
                    <br />
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        multiple
                        value={get_permissions_diff(
                          ALL_PERMISSIONS,
                          employeeData.account.user_permissions
                        )}
                        onChange={(
                          event: React.SyntheticEvent<Element, Event>,
                          newValue: Permission[]
                        ) => {
                          const user_permissions = get_permissions_diff(
                            ALL_PERMISSIONS,
                            newValue
                          );

                          console.log('user_permissions=', user_permissions);
                          setEmployeeData({
                            ...employeeData,
                            account: {
                              user_permissions: user_permissions,
                            },
                          });
                        }}
                        inputValue={permissionInput}
                        onInputChange={(
                          event: any,
                          newInputValue: string,
                          reason
                        ) => {
                          if (reason === 'input')
                            setPermissionInput(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={ALL_PERMISSIONS}
                        getOptionLabel={(option) => option.representation}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        groupBy={(option) =>
                          option.representation
                            .split(' ')
                            .slice(1)
                            .join(' ')
                            .toLocaleUpperCase()
                        }
                        renderOption={(params, option) => (
                          <li {...params} key={option.id}>
                            {option.representation}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Restrictions (*)" />
                        )}
                      />

                      <FormHelperText>
                        (*) Restrictions are actions that the employee is not
                        allowed to perform on the system.
                      </FormHelperText>
                    </FormControl>
                    <br />
                    <br />
                  </>
                )}

                <div
                  style={{
                    display: 'flex',
                    gap: 3,
                    alignItems: 'center',
                  }}
                >
                  {defaultEmployeeId && (
                    <>
                      <Switch
                        onChange={() =>
                          setEnableModifyPassword(!enableModifyPassword)
                        }
                        checked={enableModifyPassword}
                      />
                      {!enableModifyPassword && <>Modify employee password</>}
                    </>
                  )}

                  {(!defaultEmployeeId || enableModifyPassword) && (
                    <FormControl fullWidth>
                      <TextField
                        label="Employee Password"
                        type="password"
                        variant="outlined"
                        required
                        value={employeeData.password}
                        onChange={(e) =>
                          setEmployeeData({
                            ...employeeData,
                            password: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  )}
                </div>

                <br />

                <div style={{ display: 'flex', gap: 7 }}>
                  <Button type="submit" variant="contained">
                    Save Employee
                  </Button>

                  {employeeData.id !== '' && (
                    <Button
                      onClick={() => {
                        setConfirmDeleteEmployee(true);
                      }}
                      variant="outlined"
                      color="error"
                    >
                      Delete Employee
                    </Button>
                  )}
                </div>
              </form>{' '}
            </>
          )}
        </>
      )}

      <ConfirmAction
        message={'Are you sure you want to delete this employee?'}
        action={deleteEmployee}
        isOpen={confirmDeleteEmployee}
        handleClose={() => setConfirmDeleteEmployee(false)}
      >
        <div> Attention: This action can not be undone</div>
      </ConfirmAction>

      <Dialog fullWidth open={creatingNewBranchOpen}>
        <div
          style={{
            textAlign: 'right',
            width: '100%',
          }}
        >
          <IconButton onClick={() => setCreatingNewBranchOpen(false)}>
            <ClearIcon color="primary" fontSize="large" />
          </IconButton>
        </div>

        <DialogContent>
          <CreateBranchForm />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateEmployeeForm;

import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BusinessIcon from '@mui/icons-material/Business';
import MopedIcon from '@mui/icons-material/Moped';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import InventoryIcon from '@mui/icons-material/Inventory';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SearchIcon from '@mui/icons-material/Search';
import { UserType } from '../../../enums/users';
import LabelIcon from '@mui/icons-material/Label';
import { User } from '../../../interfaces/common/authentication';
import { UserHasPermission } from '../../../utils/permissions';
import SettingsIcon from '@mui/icons-material/Settings';
export const drawerWidth = '275px';

interface MenuDefinitionItem {
  name: string;
  icon: JSX.Element;
  route_substring: string;
  route: string;
}

const admin_menu_definition: MenuDefinitionItem[] = [
  { name: 'Home', icon: <HomeIcon />, route_substring: 'home', route: 'home' },
  {
    name: 'Orders',
    icon: <InventoryIcon />,
    route_substring: 'order',
    route: 'orders',
  },
  {
    name: 'Customers',
    icon: <EmojiPeopleIcon />,
    route_substring: 'customer',
    route: 'customers',
  },
  {
    name: 'Merchants',
    icon: <BusinessIcon />,
    route_substring: 'merchant',
    route: 'merchants',
  },
  {
    name: 'Drivers',
    icon: <MopedIcon />,
    route_substring: 'driver',
    route: 'drivers',
  },
  {
    name: 'Employees',
    icon: <SupportAgentIcon />,
    route_substring: 'employee',
    route: 'employees',
  },
  {
    name: 'Branches',
    icon: <StoreIcon />,
    route_substring: 'branch',
    route: 'branches',
  },
  {
    name: 'Labels',
    icon: <LabelIcon />,
    route_substring: 'label',
    route: 'labels',
  },
  {
    name: 'Accounting',
    icon: <AttachMoneyIcon />,
    route_substring: 'accounting',
    route: 'accounting',
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    route_substring: 'settings',
    route: 'settings',
  },
];

const employee_menu_definition = (user?: User): MenuDefinitionItem[] => {
  const items: MenuDefinitionItem[] = []
  if (!user) return items;

  if (UserHasPermission(user, "order"))
    items.push({
      name: 'Orders',
      icon: <InventoryIcon />,
      route_substring: 'order',
      route: 'orders',
    });


  items.push({
    name: 'Customers',
    icon: <EmojiPeopleIcon />,
    route_substring: 'customer',
    route: 'customers',
  },)

  if (UserHasPermission(user, "merchant"))
    items.push({
      name: 'Merchants',
      icon: <BusinessIcon />,
      route_substring: 'merchant',
      route: 'merchants',
    });

  if (UserHasPermission(user, "driver"))
    items.push({
      name: 'Drivers',
      icon: <MopedIcon />,
      route_substring: 'driver',
      route: 'drivers',
    });

  if (UserHasPermission(user, "labelkey"))
    items.push({
      name: 'Labels',
      icon: <LabelIcon />,
      route_substring: 'label',
      route: 'labels',
    });

  if (UserHasPermission(user, "invoicemeta"))
    items.push({
      name: 'Accounting',
      icon: <AttachMoneyIcon />,
      route_substring: 'accounting',
      route: 'accounting',
    });

  return items

};

const driver_menu_definition: MenuDefinitionItem[] = [
  {
    name: 'Order Lookup',
    icon: <SearchIcon />,
    route_substring: 'driver-order-lookup',
    route: 'driver-order-lookup',
  },
  {
    name: 'Invoices',
    icon: <AttachMoneyIcon />,
    route_substring: 'invoice',
    route: 'invoices',
  },
];

const merchant_menu_definition: MenuDefinitionItem[] = [
  {
    name: 'My Orders',
    icon: <InventoryIcon />,
    route_substring: 'order',
    route: 'merchant-orders',
  },
  {
    name: 'Invoices',
    icon: <AttachMoneyIcon />,
    route_substring: 'invoice',
    route: 'invoices',
  },
];

export const get_menu_definition = (user?: User | null): MenuDefinitionItem[] => {
  if (!user) return []
  const permissions = user.permissions
  console.log(user)
  if (user.type === UserType.EMPLOYEE && user.is_admin)
    return admin_menu_definition;

  switch (user.type) {
    case UserType.ORGANISATION: {
      return admin_menu_definition;
    }
    case UserType.EMPLOYEE: {
      return employee_menu_definition(user);
    }
    case UserType.DRIVER: {
      return driver_menu_definition;
    }
    case UserType.MERCHANT: {
      return merchant_menu_definition;
    }
    default: {
      return [];
    }
  }
};


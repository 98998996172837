import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';

import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DriverType } from '../../../../interfaces/delivery-company/drivers';
import { useContext } from 'react';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { UserType } from '../../../../enums/users';
import PersonOffIcon from '@mui/icons-material/PersonOff';

interface DriversListingTableProps {
  drivers: DriverType[];
  isLoading: boolean;
}

function DriversListingTable({ drivers, isLoading }: DriversListingTableProps) {
  const { user } = useContext(AuthContext);

  const canAssignOrders =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('change_order');

  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Phone</b>
              </TableCell>

              {canAssignOrders && (
                <TableCell align="center">
                  <b>Assign Orders</b>{' '}
                </TableCell>
              )}

              <TableCell align="center">
                <b>Has Account</b>{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.map((driver) => (
              <TableRow key={driver.id}>
                <TableCell align="center" style={{ cursor: 'pointer' }}>
                  <a href={`/driver/${driver.id}`}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 3,
                        justifyContent: 'center',
                      }}
                    >
                      <b>{driver.name}</b>

                      {driver.is_deleted && (
                        <PersonOffIcon style={{ color: 'red' }} />
                      )}
                    </div>
                  </a>
                </TableCell>
                <TableCell align="center">{driver.phone_number}</TableCell>

                {canAssignOrders && (
                  <TableCell align="center">
                    <a
                      href={
                        driver.is_deleted
                          ? '#'
                          : `/driver-order-assigner/${driver.id}`
                      }
                    >
                      <Chip
                        style={{ cursor: 'pointer' }}
                        icon={<AssignmentReturnIcon />}
                        color="primary"
                        label={`Assign Orders`}
                        variant={'outlined'}
                        disabled={driver.is_deleted}
                      />
                    </a>
                  </TableCell>
                )}
                <TableCell align="center">
                  {!driver.has_usable_password ? (
                    <CancelIcon sx={{ color: 'red' }} />
                  ) : (
                    <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                  )}{' '}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default DriversListingTable;

import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { Box, Typography, Button, Avatar, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';

interface LogoUploaderProps {
    logoUrl: string;
    updateLogoUrl: (url: string) => void;
}

function LogoUploader({ logoUrl, updateLogoUrl }: LogoUploaderProps) {
    const { user } = useContext(AuthContext);
    const [selectedLogo, setSelectedLogo] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(logoUrl);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);  // Success message state

    const config = {
        headers: { Authorization: `Bearer ${user?.authToken}` },
    };



    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null)
        setSuccessMessage(null)

        const selectedFile = event.target.files?.[0];

        if (!selectedFile) return;

        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

        // Check file type (client-side validation)
        if (!['png', 'jpeg', 'jpg'].includes(fileExtension || '')) {
            setError('Only PNG and JPEG/JPG images are allowed.');
            setSelectedLogo(null);
            return;
        }

        setError(null); // Reset any previous error
        setSelectedLogo(selectedFile); // Set the selected file

        // Generate a preview URL for the selected image
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreviewUrl(objectUrl);
    };

    // Handle form submission
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!selectedLogo) {
            setError('Please select a logo to upload.');
            return;
        }

        setUploading(true);
        setError(null);
        setSuccessMessage(null);  // Reset the success message before uploading

        const formData = new FormData();
        formData.append('logo', selectedLogo);

        try {
            // Replace with your actual API endpoint
            const response = await axios.post(`${API_ENDPOINT}/api/org/logo`, formData, config);

            // On success, show the success message
            setUploading(false);
            setSuccessMessage('Logo uploaded successfully!');
            updateLogoUrl(`https://parcel-tracer-public.s3.eu-central-1.amazonaws.com/${user?.organisation?.org_id}/logo.png`)
            setSelectedLogo(null); // Clear selected file after successful upload
        } catch (err) {
            setUploading(false);
            setError('Failed to upload the logo. Please try again.');
        }
    };

    return (
        <Box sx={{ maxWidth: 400, margin: 'auto', padding: 3, textAlign: 'center' }}>

            {/* Error and Success Messages */}
            {error && <Typography color="error" sx={{ marginBottom: 2 }}>{error}</Typography>}
            {successMessage && (
                <Alert severity="success" sx={{ marginBottom: 2 }}>
                    {successMessage}
                </Alert>
            )}

            {/* Preview Section */}
            <Box sx={{ marginBottom: 2 }}>
                {previewUrl ? (
                    <Avatar
                        src={previewUrl}
                        sx={{
                            width: 120,
                            height: 120,
                            borderRadius: '8px',
                            marginX: 'auto',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    />
                ) : (
                    <Avatar
                        sx={{
                            width: 120,
                            height: 120,
                            borderRadius: '8px',
                            marginX: 'auto',
                            backgroundColor: '#f0f0f0',
                            border: '2px dashed #b0b0b0',
                        }}
                    />
                )}
                <Typography variant="body2" sx={{ color: '#777', marginTop: 1 }}>
                    {previewUrl ? 'Preview of your logo' : 'No logo selected'}
                </Typography>
            </Box>

            {/* File Upload Button */}
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="logo-file-input"
            />
            <label htmlFor="logo-file-input">
                <Button variant="outlined" component="span" sx={{ width: '100%', marginBottom: 2 }}>
                    {selectedLogo ? 'Change Logo' : 'Choose Logo'}
                </Button>
            </label>

            {/* Upload Button */}
            <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                disabled={uploading || !selectedLogo}
                sx={{
                    paddingY: 1.5,
                }}
            >
                {uploading ? <CircularProgress size={24} sx={{ color: 'white', marginRight: 2 }} /> : 'Upload Logo'}
            </Button>
        </Box>
    );
}

export default LogoUploader;

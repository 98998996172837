import React, { useContext } from 'react';
import {
  EmployeePermissionType,
  EmployeeType,
} from '../create-employee-form/create-employee-form.interfaces';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  LinearProgress,
} from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import { get_permissions_diff } from '../util';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { PermissionsContext } from '../../../../contexts/user-context/permissions-context';

interface EmployeesListingTableProps {
  employees: EmployeeType[];
  isLoading: boolean;
}

function EmployeesListingTable({
  employees,
  isLoading,
}: EmployeesListingTableProps) {
  const navigate = useNavigate();

  const { permissions } = useContext(PermissionsContext);
  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Phone</b>
              </TableCell>
              <TableCell align="center">
                <b>Admin</b>
              </TableCell>
              <TableCell align="center">
                <b>Branch</b>
              </TableCell>
              <TableCell align="center">
                <b>Restrictions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell align="center" style={{ cursor: 'pointer' }}>
                  <a href={`/employee/${employee.id}`}>
                    <b>{employee.name}</b>
                  </a>
                </TableCell>
                <TableCell align="center">{employee.phone_number}</TableCell>
                <TableCell align="center">
                  {employee.is_admin ? (
                    <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                  ) : (
                    <CancelIcon sx={{ color: 'red' }} />
                  )}{' '}
                </TableCell>
                <TableCell align="center">
                  <>
                    {employee.branch && (
                      <>
                        <a href={`/branch/${employee.branch.id}`}>
                          <b>{employee.branch.name}</b>
                        </a>
                      </>
                    )}
                  </>
                </TableCell>
                <TableCell align="center">

                  {!employee.is_admin && get_permissions_diff(
                    permissions,
                    employee.account.user_permissions,
                  ).map((permission: EmployeePermissionType) => (
                    <Chip
                      key={permission.name}
                      icon={<BlockIcon />}
                      color="secondary"
                      label={permission.representation}
                      variant="filled"
                      style={{
                        margin: 3,
                      }}
                    />
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default EmployeesListingTable;

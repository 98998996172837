import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  primaryPaletteColor,
  secondaryPaletteColor,
} from './configurations/global.config';
import { enableMapSet } from 'immer';

import AppLayout from './components/app-layout/app-layout';
import { AuthProvider } from './contexts/user-context/user-context';
import { Routes, Route } from 'react-router-dom';
import DeliveryTracker from './components/delivery-tracker/delivery-tracker';
import { Fab, TextField } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { PermissionsContextProvider } from './contexts/user-context/permissions-context';
import { generateWhatsAppLink } from './components/driver-components/driver-order-lookup/utils';
import { PaginationProvider } from './contexts/pagination-context';
import { OrgThemeContext, OrgThemeProvider } from './contexts/theme-context';
import { useContext } from 'react';

interface ThemeWrapperProps {
  children: React.ReactNode;
}

const DefaultThemeWrapper = ({ children }: ThemeWrapperProps) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: primaryPaletteColor,
      },
      secondary: {
        main: secondaryPaletteColor,
      },
    },
  });

  theme.typography.h3 = {
    fontSize: '1.6rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const CustomThemeProvider = ({ children }: ThemeWrapperProps) => {
  const { color } = useContext(OrgThemeContext);
  const theme = createTheme({
    palette: {
      primary: {
        main: color.length === 0 ? primaryPaletteColor : color,
      },
      secondary: {
        main: secondaryPaletteColor,
      },
    },
  });

  theme.typography.h3 = {
    fontSize: '1.6rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function App() {
  enableMapSet();
  return (
    <div className="app">
      <Routes>
        <Route path="/tracking">
          <Route
            path=":hash"
            element={
              <DefaultThemeWrapper>
                <DeliveryTracker />

                <Fab
                  color="primary"
                  size="large"
                  aria-label="add"
                  style={{
                    position: 'fixed',
                    zIndex: 9999999,
                    bottom: 7,
                    right: 7,
                  }}
                  onClick={() =>
                    window.open(generateWhatsAppLink('+33753560667'))
                  }
                >
                  <ContactSupportIcon />
                </Fab>
              </DefaultThemeWrapper>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <PaginationProvider>
              <AuthProvider>
                <OrgThemeProvider>
                  <CustomThemeProvider>
                    <PermissionsContextProvider>
                      <AppLayout />

                      <Fab
                        color="primary"
                        size="large"
                        aria-label="add"
                        style={{
                          position: 'fixed',
                          zIndex: 9999999,
                          bottom: 7,
                          right: 7,
                        }}
                        onClick={() =>
                          window.open(generateWhatsAppLink('+33753560667'))
                        }
                      >
                        <ContactSupportIcon />
                      </Fab>
                    </PermissionsContextProvider>
                  </CustomThemeProvider>
                </OrgThemeProvider>
              </AuthProvider>
            </PaginationProvider>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;

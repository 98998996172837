import React from 'react';
import { Box, Typography } from '@mui/material';

interface SectionProps {
    title: string;
    children: React.ReactNode;
}

function Section({ title, children }: SectionProps) {
    return (
        <Box
            sx={{
                mb: 4,
                padding: 2,
                borderRadius: 2,
                border: '1px solid #ddd',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                backgroundColor: '#fff', // Clean white background
                transition: 'all 0.3s ease', // Smooth transition on hover
                '&:hover': {
                    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)', // Darker shadow on hover
                    borderColor: '#bbb', // Slightly darker border on hover
                },
            }}
        >
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2 }}>
                {title}
            </Typography>
            {/* Content */}
            <Box sx={{ paddingTop: 1 }}>{children}</Box>
        </Box>
    );
}

export default Section;

import {
  Autocomplete,
  TextField
} from '@mui/material';
import _debounce from 'lodash/debounce';
import { ReactNode, useState } from 'react';
import { FilterMethods } from '../filter-bar/filter-bar.interface';

interface MultipleAutocompleteWithThrottleProps<T> {
  label: string;
  onClickAdd?: () => void;
  onChange: (
    e: React.SyntheticEvent<Element, Event>,
    value: T[] | null
  ) => void;
  fetch: (query: string) => Promise<T[]>;
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  filterHelp: ReactNode;
  value?: T[];
}

function MultipleAutocompleteWithThrottle<T extends FilterMethods>({
  value,
  isOptionEqualToValue,
  label,
  onClickAdd,
  fetch,
  getOptionLabel,
  onChange,
  filterHelp,
}: MultipleAutocompleteWithThrottleProps<T>) {
  const [matchingOptions, setMatchingOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentInput, setCurrentInput] = useState<string>('');

  const debouncedFetchOptions = _debounce(async (query) => {
    if (query && query !== '') {
      try {
        setLoading(true);
        const options = await fetch(query);
        setMatchingOptions(options);
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setMatchingOptions([]);
    }
  }, 500);

  const handleInputChange = (event: any) => {
    if (event) {
      const inputValue = event.target.value;
      setCurrentInput(inputValue);
      debouncedFetchOptions(inputValue);
    }
  };

  return (
    <Autocomplete
      disablePortal
      options={matchingOptions}
      getOptionLabel={(option) => (option ? getOptionLabel(option) : '')}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={handleInputChange}
      filterOptions={(x) => x}
      loading={loading}
      onChange={onChange}
      defaultValue={value ? value : undefined}
      value={value}
      noOptionsText={currentInput === '' ? filterHelp : 'No options'}
      multiple
      renderInput={(params) => (
        <TextField required {...params} label={label} variant="outlined" />
      )}
    />
  );
}

export default MultipleAutocompleteWithThrottle;

import {
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  Autocomplete,
  LinearProgress,
  Dialog,
  IconButton,
  DialogContent,
  FormHelperText,
  Switch,
} from '@mui/material';
import { useContext, useState } from 'react';

import React from 'react';
import MuiPhoneNumber from 'mui-phone-number';
import ConfirmAction from '../../../common/confirm-action/confirm-action';
import axios, { AxiosResponse } from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';

import { API_ENDPOINT } from '../../../../configurations/global.config';
import { CreateLabelFormProps } from './create-label-form.interfaces';
import {
  LabelKeyWithValues,
  LabelValue,
} from '../../../../interfaces/delivery-company/labels';
import MultiTextField from '../../../common/multi-text-field/multi-text-field';
import { UserType } from '../../../../enums/users';
import { ErrorsFlattenner } from '../../../common/errors-flattenner/errors-flattenner';

function CreateLabelForm({ defaultLabelId }: CreateLabelFormProps) {
  const [labelWithValues, setLabelWithValues] = useState<LabelKeyWithValues>();
  const [values, setValues] = useState<LabelValue[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');

  const { user } = useContext(AuthContext);
  const canChangeLabel =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('change_labelkey');
  const canCreateLabel =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('add_labelkey');
  const canDeleteLabel =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('delete_labelkey');

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  React.useEffect(() => {
    const getAndLabels = async () => {
      if (defaultLabelId !== undefined) {
        setIsLoading(true);
        try {
          const response = await axios.get<LabelKeyWithValues>(
            `${API_ENDPOINT}/api/org/labelkeys/${defaultLabelId}`,
            config
          );
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setLabelWithValues(response.data);
            setValues(response.data.values);
          }
        } catch (e) {
          setLabelWithValues(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load employee  data: ' + e.message);
          } else {
            setMessage('Failed to load employee  data');
          }
          console.log(e);
        }
        setIsLoading(false);
      } else {
        setLabelWithValues({ key: '', id: '', values: [] });
      }
    };
    getAndLabels();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (user === null) return;
    if (labelWithValues) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Label...');

      const requestData = {
        key: labelWithValues.key,
        values: values,
      };

      console.log(requestData);

      try {
        let response;

        console.log('Request Data: ');
        console.log(requestData);

        if (defaultLabelId === undefined) {
          // create new
          response = await axios.post(
            `${API_ENDPOINT}/api/org/labelkeys/`,
            requestData,
            config
          );
        } else {
          // edit existing
          const endpoint = `${API_ENDPOINT}/api/org/labelkeys/${labelWithValues.id}/`;
          response = await axios.patch(endpoint, requestData, config);
        }
        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Labels saved successfully');
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to save merchant: ' + e.message);
        } else {
          setMessage('Failed to save merchant');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteLabel = async () => {
    if (labelWithValues) {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/org/labelkeys/${labelWithValues.id}/`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setLabelWithValues(undefined);
          setMessage('Labels deleted successfully');
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to save merchant: ' + e.message);
        } else {
          setMessage('Failed to save merchant');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        {defaultLabelId === undefined ? 'Create Label' : 'Edit Label'}
      </Typography>
      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message !== '' && (
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              {message}

              {response && response.status >= 300 && (
                <ErrorsFlattenner rawErrors={response.data} />
              )}
            </Alert>
          )}

          {labelWithValues && (
            <>
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Label Key"
                    type="text"
                    variant="outlined"
                    required
                    disabled={!canChangeLabel}
                    value={labelWithValues.key}
                    onChange={(e) =>
                      setLabelWithValues({
                        ...labelWithValues,
                        key: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <br />

                <MultiTextField
                  values={values}
                  setValues={setValues}
                  disabled={!canChangeLabel}
                  label={'Label Values'}
                />

                <br />

                <div style={{ display: 'flex', gap: 7 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      (labelWithValues.id !== '' && !canChangeLabel) ||
                      (labelWithValues.id === '' && !canCreateLabel)
                    }
                  >
                    Save
                  </Button>

                  {labelWithValues.id !== '' && (
                    <Button
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                      variant="outlined"
                      color="error"
                      disabled={!canDeleteLabel}
                    >
                      Delete Label
                    </Button>
                  )}
                </div>
              </form>{' '}
            </>
          )}
        </>
      )}

      <ConfirmAction
        message={'Are you sure you want to delete this label?'}
        action={deleteLabel}
        isOpen={confirmDelete}
        isSevereAction={true}
        confirmSevereActionTarget={'delete ' + labelWithValues?.key}
        handleClose={() => setConfirmDelete(false)}
      >
        <div>
          {' '}
          Attention: This action can not be undone
          <Alert severity="info">
            Deleting this label will not delete orders belonging to this branch.{' '}
          </Alert>
        </div>
      </ConfirmAction>
    </>
  );
}

export default CreateLabelForm;

import { StyleSheet } from '@react-pdf/renderer';

export const Br = () => '\n';

export const pageStyles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 20,
  },
  logo: {
    height: 80,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export const titleStyles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    textAlign: 'center',
    width: '100%',
  },
  sectionTitle: {
    letterSpacing: 1,
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  reportTitle: {
    letterSpacing: 1,
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subtitle: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

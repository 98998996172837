import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Drawer } from '@mui/material';
import React, { useContext, useState } from 'react';
import { DrawerHeader } from '../../app-layout/side-nav/side-nav.utils';
import { CreateEntityDrawerProps } from './create-entity-drawer.interfaces';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { UserType } from '../../../enums/users';
import { OrgThemeContext } from '../../../contexts/theme-context';

function CreateEntityDrawer({
  children,
  onClose,
  buttonFullWidth,
  disabled,
}: CreateEntityDrawerProps) {
  const [open, setIsOpen] = useState(false);
  const { color } = useContext(OrgThemeContext);

  const toggleDrawer =
    (is_open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsOpen(is_open);
    };

  return (
    <div>
      <Button
        variant="contained"
        fullWidth={buttonFullWidth}
        onClick={toggleDrawer(true)}
        disabled={disabled}
      >
        Create New
      </Button>
      <Drawer
        style={{ zIndex: 10 }}
        anchor={'right'}
        open={open}
        onClose={() => {
          toggleDrawer(false);
        }}
      >
        <DrawerHeader />
        <div>
          <span
            style={{
              margin: 10,
              cursor: 'pointer',
              color: `${color}`,
              display: 'inline-block',
              overflow: 'hidden',
            }}
            onClick={() => {
              onClose && onClose();
              setIsOpen(false);
            }}
          >
            <CloseIcon sx={{ fontSize: 40 }} />
          </span>
        </div>

        <Box
          sx={{
            width: `${window.innerWidth < 1000 ? '90vw' : '35vw'}`,
            padding: 2,
          }}
        >
          {children}
        </Box>
      </Drawer>
    </div>
  );
}

export default CreateEntityDrawer;

import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { DeliveryState, PaymentState } from '../../../enums/orders';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { BulkActionProps } from './props.interface';
import { ErrorsFlattenner } from '../errors-flattenner/errors-flattenner';

function SetDeliveryStateAction({
  selectedOrders,
  bulkEndpoint,
}: BulkActionProps) {
  const [deliveryState, setDeliveryState] = React.useState<DeliveryState>(
    DeliveryState.PENDING_APPROVAL
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [response, setResponse] = React.useState<AxiosResponse>();

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };
  const orgId = user?.organisation?.org_id;

  const handleSubmit = async () => {
    let endpoint = bulkEndpoint;
    if (orgId) {
      endpoint += `?org=${encodeURIComponent(orgId)}`;
    }

    setIsLoading(true);
    setMessage('');
    setResponse(undefined);

    const selectedOrdersList = Array.from(selectedOrders.values()).filter(
      (order) => order.delivery_state !== deliveryState
    );

    if (
      [DeliveryState.IN_TRANSIT, DeliveryState.DELIVERED].includes(
        deliveryState
      )
    ) {
      if (selectedOrdersList.some((order) => !order.driver)) {
        setMessage(
          'Some of the orders you selected are not yet assigned to a driver.'
        );
        setIsLoading(false);
        return;
      }
    }

    if (deliveryState !== DeliveryState.PENDING_APPROVAL) {
      if (selectedOrdersList.some((order) => !order.order_id)) {
        setMessage(
          'Some of the orders you selected are not yet assigned an order id'
        );
        setIsLoading(false);
        return;
      }
    }

    if (selectedOrdersList.length === 0) {
      setMessage(
        `All of the selected orders already have the requested delivery state: ${deliveryState}`
      );
      setIsLoading(false);
      return;
    }

    const requestData = selectedOrdersList.map((order) => {
      const item: any = {
        id: order.id,
        delivery_state: deliveryState,
      };

      return item;
    });
    try {
      const response = await axios.patch<OrderType[]>(
        endpoint,
        requestData,
        config
      );
      setResponse(response);
      if (response.statusText == 'OK') {
        setMessage('Orders updated successfully');
      } else {
        setMessage('Failed to update orders');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setResponse(e.response);
        setMessage('Failed to update orders: ' + e.response?.statusText);
      } else {
        setMessage('Failed to update orders');
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select">Delivery State</InputLabel>
            <Select
              required
              fullWidth
              id="demo-simple-select"
              value={Object.values(DeliveryState).indexOf(deliveryState)}
              label="Delivery State"
              onChange={(e) => {
                const index = parseInt(e.target.value.toString());
                const state = Object.values(DeliveryState)[index];
                setDeliveryState(state);
                setMessage('');
                setResponse(undefined);
              }}
            >
              {Object.values(DeliveryState).map((state, index) => (
                <MenuItem key={index} value={index}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br></br>

          {message !== '' ? (
            <>
              <br />
              <Alert
                severity={
                  response && response.status >= 200 && response.status < 300
                    ? 'success'
                    : 'error'
                }
              >
                <>
                  {message}

                  {response && response.status >= 300 && (
                    <ErrorsFlattenner rawErrors={response.data} />
                  )}
                </>
              </Alert>
            </>
          ) : (
            <>
              {' '}
              <br />
              <Alert severity="info">
                This action will reset payment state to uninvoiced.
              </Alert>
            </>
          )}

          <br />
          <FormControl>
            <Button
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
            >
              {' '}
              Save{' '}
            </Button>
          </FormControl>
        </>
      )}
    </>
  );
}

export default SetDeliveryStateAction;

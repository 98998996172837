import { filter } from 'lodash';
import { DefaultFilter } from './filter-types';

export const buildQueryParams = (filters: DefaultFilter[]): string => {
  let queryParams = '';

  // filters.forEach((filter) => {
  //   queryParams += `&${filter.to_query()}`;
  // });

  const aggregatedFilters = new Map<string, string[]>();

  filters.forEach((filter) => {
    const { key, value } = filter.to_query_pair();
    if (aggregatedFilters.has(key)) {
      aggregatedFilters.get(key)?.push(value);
    } else {
      aggregatedFilters.set(key, [value]);
    }
  });

  console.log(aggregatedFilters);

  Array.from(aggregatedFilters.keys()).forEach((key: string) => {
    const values = aggregatedFilters.get(key);
    if (!values) return;
    const encodedValues = values.map((value: string) =>
      encodeURIComponent(value)
    );
    queryParams += `&${key}=${encodedValues.join(',')}`;
  });

  return queryParams;
};

interface parseableFilter {
  k: string;
  v: string;
  islabel: boolean;
}

export const buildJSONQuery = (filters: DefaultFilter[]): string => {
  const filterList: parseableFilter[] = [];
  filters.forEach((filter) =>
    filterList.push({
      k: filter.get_key(),
      v: filter.get_value(),
      islabel: filter.labelId != undefined,
    })
  );

  return JSON.stringify(filterList);
};
